import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as BLOGCATEGORY from "../../actions/BlogCategory/BlogCategory.actions";

const initialState = {
  blogCategories: null,
  blogCategoryObj: null,
  loading: false,
  error: null,
};

export const blogCategoryReducer = (state = initialState, action) => {
  // console.log(action, "reduscer");
  switch (action.type) {
    case BLOGCATEGORY.BLOGCATEGORY_ADD:
      return {
        ...state,
        loading: true,
      };
    case BLOGCATEGORY.BLOGCATEGORY_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case BLOGCATEGORY.BLOGCATEGORY_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BLOGCATEGORY.GET_ALL_BLOGCATEGORY:
      return {
        ...state,
        loading: true,
      };
    case BLOGCATEGORY.GET_ALL_BLOGCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        blogCategories: action.payload.data,
      };
    case BLOGCATEGORY.GET_ALL_BLOGCATEGORY_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BLOGCATEGORY.DELETE_BLOGCATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case BLOGCATEGORY.DELETE_BLOGCATEGORY_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case BLOGCATEGORY.DELETE_BLOGCATEGORY_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BLOGCATEGORY.SET_BLOGCATEGORY_OBJ:
      return {
        ...state,
        loading: true,
      };
    case BLOGCATEGORY.SET_BLOGCATEGORY_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        blogCategoryObj: action.payload.data,
        loading: false,
        error: null,
      };
    case BLOGCATEGORY.SET_BLOGCATEGORY_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case BLOGCATEGORY.UPDATE_BLOGCATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case BLOGCATEGORY.UPDATE_BLOGCATEGORY_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case BLOGCATEGORY.UPDATE_BLOGCATEGORY_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
