import { Route, Routes } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/scss/main.css";
import Profile from "../components/Admin/Profile";
import AddBlog from "../components/Blog/AddBlog";
import Blog from "../components/Blog/Blog";
import BlogCategory from "../components/Blog/BlogCategory";

import Coupon from "../components/Coupon/Coupon";

import ShowBlog from "../components/Blog/ShowBlog";
import CompanyInfo from "../components/CompanyInfo/CompanyInfo";
import ContactMail from "../components/ContactMail/ContactMail";
import ShowContact from "../components/ContactMail/ShowContact";
import AddCustomer from "../components/Customer/AddCustomer";
import Customer from "../components/Customer/Customer";
import ProductDetail from "../components/Customer/CustomerDetail";
import Dashboard from "../components/dashboard/Dashboard";
import AddEmailTemplate from "../components/EmailTemplate/AddEmailTemplate";
import EmailTemplate from "../components/EmailTemplate/EmailTemplate";
import AddBanner from "../components/Frontend-CMS/Banners/AddBanner";
import Banners from "../components/Frontend-CMS/Banners/Banners";
import AddContactInfo from "../components/Frontend-CMS/ContactInfo/AddContactInfo";
import ContactInfo from "../components/Frontend-CMS/ContactInfo/ContactInfo";
import AddFaq from "../components/Frontend-CMS/FAQ/AddFaq";
import Faq from "../components/Frontend-CMS/FAQ/Faq";
import AddLogo from "../components/Frontend-CMS/Logo/AddLogo";
import Logo from "../components/Frontend-CMS/Logo/Logo";
import AddPrivacy from "../components/Frontend-CMS/PrivacyPolicy/AddPrivacy";
import Privacy from "../components/Frontend-CMS/PrivacyPolicy/Privacy";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import AddMenu from "../components/Menus/AddMenu";
import EditMenu from "../components/Menus/EditMenu";
import MenuItem from "../components/Menus/MenuItem";
import Menus from "../components/Menus/Menus";
import CancleReason from "../components/Order/CancleReason/CancleReason";
import DeliveryProcess from "../components/Order/DeliveryProcess/DeliveryProcess";
import SaleDetail from "../components/Order/Detail/SaleDetail";
import InHouse from "../components/Order/InhouseOrder/Inhouse";
import TotalOrder from "../components/Order/TotalOrder/TotalOrder";
import TrackOrder from "../components/Order/TrackOrder/TrackOrder";
import PaymentGateway from "../components/PaymentGateway/PaymentGateway";
import AddProduct from "../components/Products/AddProduct/AddProduct";
import Attribute from "../components/Products/Attribute/Attribute";
import AttributeValue from "../components/Products/Attribute/AttributeValue";
import AddBrand from "../components/Products/Brand/AddBrand";
import Brand from "../components/Products/Brand/Brand";
import BulkBrandUpload from "../components/Products/Brand/BulkBrandUpload";
import BulkProductUpload from "../components/Products/BulkProductUpload";
import BulkCategoryUpload from "../components/Products/Category/BulkCategoryUpload";
import Category from "../components/Products/Category/Category";
import Config from "../components/Products/Config";
import ProductList from "../components/Products/ProductList";
import ProductReview from "../components/Review/ProductReview";
import SellerReview from "../components/Review/SellerReview";
import ReviewConfig from "../components/Review/ReviewConfig";
import AddCurrency from "../components/SetUp/CurrencyList/AddCurrency";
import CurrencyList from "../components/SetUp/CurrencyList/CurrencyList";
import Location from "../components/SetUp/Location/Location";
import Shipping from "../components/SetUp/Shipping/Shipping";
import Tags from "../components/SetUp/Tags/Tags";
import SideBar from "../components/Sidebar/SideBar";
import GstConfig from "../components/Tax/GstConfig";
import GstSetup from "../components/Tax/GstSetup";
import AddUser from "../components/Users/AddUser";
import EditUser from "../components/Users/EditUser";
import UserDetail from "../components/Users/UserDetail";
import User from "../components/Users/Users";

import AccountsLedger from "../components/AccountsLedger/AccountsLedger";
import SettlementDetails from "../components/dashboard/SettlementDetails";
import NotificationsAdd from "../components/EmailTemplate/AddNotifications";
import NotificationsView from "../components/EmailTemplate/Notifications";
import CloneGeneralProduct from "../components/Products/AddProduct/CloneProduct";
import UpdateProductListPage from "../components/Products/AddProduct/UpdateProductListPage";
import GeneralSettings from "../components/SelllerSettings/GeneralSettings";
import ViewStates from "../components/Location/States/ViewStates";
import AddStates from "../components/Location/States/AddStates";
import AddArea from "../components/Location/Area/AddArea";
import ViewArea from "../components/Location/Area/ViewArea";
import ViewWareHouse from "../components/WareHouse/ViewWareHouse";
import AddWareHouse from "../components/WareHouse/AddWareHouse";
import AddCity from "../components/Location/City/AddCity";
import ViewCity from "../components/Location/City/ViewCity";
import AddGalleryImage from "../components/Frontend-CMS/Gallery/AddGalleryImage";
import ViewGallery from "../components/Frontend-CMS/Gallery/ViewGallery";
import Maintenance from "../components/SystemSetting/Maintenance";
import Analytics from "../components/SystemSetting/Analytics";
import EmailSettings from "../components/SystemSetting/EmailSettings";
import MailTemplates from "../components/SystemSetting/MailTemplate/MailTemplates";
import AddMailTemplate from "../components/SystemSetting/MailTemplate/AddMailTemplate";
import Order from "../components/Reports/Order";
import CustomerReports from "../components/Reports/CustomerReports";
import Subscriber from "../components/Subscriber/Subscriber";
import ProductStock from "../components/Products/ProductStock/ProductStock";
import UserOrders from "../components/Users/UserOrders";
import AddTestimonial from "../components/Testimonial/AddTestimonial";
import ViewTestimonial from "../components/Testimonial/ViewTestimonial";
import CategorySequence from "../components/Products/Category/CategorySequence";
import AddReview from "../components/Review/AddReview";
import ViewTeam from "../components/Teams/ViewTeam";
import AddTeam from "../components/Teams/AddTeam";
import Setting from "../components/SystemSetting/Setting";
import ViewMaterial from "../components/Products/Material/ViewMaterial";
import ViewSize from "../components/Products/Size/ViewSize";
import ViewColor from "../components/Products/Color/ViewColor";
import ViewProductQuantityRanges from "../components/Products/ProductQuantityRanges/ViewProductQuantityRanges";
import OrderDetail from "../components/Order/Detail/OrderDetail";



import BookAppointment from "../components/BookAppointment/BookAppointment";
import Enquiry from "../components/Enquiry/Enquiry";
import ProductCustomization from "../components/ProductCustomization/ProductCustomization";
import GetQuote from "../components/GetQuote/GetQuote";
import PasswordChange from "../components/PasswordChange/PasswordChange";
import AddHomeBanner from "../components/Frontend-CMS/HomeBanners/AddHomeBanner"
import HomeBanner from "../components/Frontend-CMS/HomeBanners/HomeBanners"

export default function AuthorizedRoutes() {
  return (
    <section className="body_bg">
      <div className="row g-0">
        <SideBar className="col-12 col-md-3 col-lg-2" />
        {/* <div className="col-12 col-lg-3 col-lg-2" style={{ contain: "content" }}>
        </div> */}
        <div className="col-12 col-md-9 col-lg-10 offset-md-3 offset-lg-2" style={{ height: "100vh", overflow: "hidden scroll" }}>
          <Header />
          <Routes>
            <Route exact path="/" element={<Dashboard />}></Route>
            <Route exact path="/Settlement-Details" element={<SettlementDetails />}></Route>
            <Route exact path="/Location/View-States" element={<ViewStates />}></Route>
            <Route exact path="/Location/Add-States" element={<AddStates />}></Route>
            <Route exact path="/Location/View-City" element={<ViewCity />}></Route>
            <Route exact path="/Location/Add-City" element={<AddCity />}></Route>
            <Route exact path="/Location/View-Area" element={<ViewArea />}></Route>
            <Route exact path="/Location/Add-Area" element={<AddArea />}></Route>
            <Route exact path="/Warehouse/View-Warehouse" element={<ViewWareHouse />}></Route>
            <Route exact path="/Warehouse/Add-Warehouse" element={<AddWareHouse />}></Route>

            <Route exact path="/Accounts-and-Ledger" element={<AccountsLedger />}></Route>
            <Route exact path="/Product/View-Product-Quantity-Ranges" element={<ViewProductQuantityRanges />}></Route>
            <Route exact path="/Product/View-Color" element={<ViewColor />}></Route>
            <Route exact path="/Product/View-Size" element={<ViewSize />}></Route>
            <Route exact path="/Product/View-Material" element={<ViewMaterial />}></Route>
            <Route exact path="/Product/Category" element={<Category />}></Route>
            <Route exact path="/Product/Category-Sequence" element={<CategorySequence />}></Route>
            <Route exact path="/Product/Brand" element={<Brand />}></Route>
            <Route exact path="/Product/clone-Product" element={<CloneGeneralProduct />}></Route>
            <Route exact path="/Product/Brand-Create" element={<AddBrand />}></Route>
            <Route exact path="/Product/Update-Product" element={<UpdateProductListPage />}></Route>
            <Route exact path="/Product/Attribute" element={<Attribute />}></Route>
            <Route exact path="/Product/Attribute-Value" element={<AttributeValue />}></Route>
            <Route exact path="/Product/AddProduct" element={<AddProduct />}></Route>
            <Route exact path="/Product/ProductStock" element={<ProductStock />}></Route>
            <Route exact path="/Product/Bulk-Product-Upload" element={<BulkProductUpload />}></Route>
            <Route exact path="/Product/Bulk-Category-Upload" element={<BulkCategoryUpload />}></Route>
            <Route exact path="/Product/Bulk-Brand-Upload" element={<BulkBrandUpload />}></Route>
            <Route exact path="/Product/Product-List" element={<ProductList />}></Route>
            <Route exact path="/ProductBlogCategory/Config" element={<Config />}></Route>
            <Route exact path="/Admin/Profile" element={<Profile />}></Route>
            <Route exact path="/Blog/post" element={<Blog />}></Route>
            <Route exact path="/Blog/post/create" element={<AddBlog />}></Route>
            <Route exact path="/Email/post" element={<EmailTemplate />}></Route>
            <Route exact path="/Email/post/create" element={<AddEmailTemplate />}></Route>
            <Route exact path="/Notification/post" element={<NotificationsView />}></Route>
            <Route exact path="/Notification/post/create" element={<NotificationsAdd />}></Route>
            <Route exact path="/Blog/Category" element={<BlogCategory />}></Route>

            <Route exact path="/Coupon/Coupon" element={<Coupon />}></Route>

            <Route exact path="/Blog/View-Post" element={<ShowBlog />}></Route>
            <Route exact path="/Order/Total-Order" element={<TotalOrder />}></Route>
            <Route exact path="/Order/Inhouse-Order" element={<InHouse />}></Route>
            <Route exact path="/Order/Delivery-Process" element={<DeliveryProcess />}></Route>
            <Route exact path="/Order/Cancle-Reason" element={<CancleReason />}></Route>
            <Route exact path="/Order/Track-Order" element={<TrackOrder />}></Route>
            <Route exact path="/Order/Sale-Detail" element={<SaleDetail />}></Route>
            <Route exact path="/Order/Order-Detail" element={<OrderDetail />}></Route>
            <Route exact path="/Contact-Mail" element={<ContactMail />}></Route>
            <Route exact path="/Contact-Info" element={<ShowContact />}></Route>
            <Route exact path="/Review/Product-Review" element={<ProductReview />}></Route>
            <Route exact path="/Review/Product-Review/Create-Review" element={<AddReview />}></Route>
            <Route exact path="/Review/Seller-Review" element={<SellerReview />}></Route>
            <Route exact path="/Review/Review-Configuration" element={<ReviewConfig />}></Route>
            <Route exact path="/settings/general" element={<GeneralSettings />}></Route>
            <Route exact path="/Company-Information" element={<CompanyInfo />}></Route>
            <Route exact path="/Menus" element={<Menus />}></Route>
            <Route exact path="/Menus/Menus-Create" element={<AddMenu />}></Route>
            <Route exact path="/Menus/Menus-Edit" element={<EditMenu />}></Route>
            <Route exact path="/Menus/Menus-Item" element={<MenuItem />}></Route>
            <Route exact path="/Payment-Gateway" element={<PaymentGateway />}></Route>
            <Route exact path="/GST-SETUP" element={<GstSetup />}></Route>
            <Route exact path="/GST-Configuation" element={<GstConfig />}></Route>
            <Route exact path="/Customer-list" element={<Customer />}></Route>
            <Route exact path="/Customer-Create" element={<AddCustomer />}></Route>
            <Route exact path="/Customer-Detail" element={<ProductDetail />}></Route>
            <Route exact path="/User-list" element={<User />}></Route>
            <Route exact path="/User-Orders/:userId" element={<UserOrders />}></Route>
            <Route exact path="/User/User-edit/:id" element={<EditUser />}></Route>
            <Route exact path="/User-Create" element={<AddUser />}></Route>
            <Route exact path="/User-Detail" element={<UserDetail />}></Route>
            <Route exact path="/Banners" element={<Banners />}></Route>
            <Route exact path="/Banners/Banner-Create" element={<AddBanner />}></Route>
            <Route exact path="/Gallery" element={<ViewGallery />}></Route>
            <Route exact path="/Gallery/Create" element={<AddGalleryImage />}></Route>
            <Route exact path="/Testimonial" element={<ViewTestimonial />}></Route>
            <Route exact path="/Testimonial/Create" element={<AddTestimonial />}></Route>
            <Route exact path="/Team" element={<ViewTeam />}></Route>
            <Route exact path="/Team/Create" element={<AddTeam />}></Route>
            <Route exact path="/Setting" element={<Setting />}></Route>

            <Route exact path="/Logo" element={<Logo />}></Route>
            <Route exact path="/Logo/Logo-Create" element={<AddLogo />}></Route>
            <Route exact path="/ContactInfo" element={<ContactInfo />}></Route>
            <Route exact path="/Contact-Info/Contact-Info-Create" element={<AddContactInfo />}></Route>
            <Route exact path="/Faq" element={<Faq />}></Route>
            <Route exact path="/Faq/Faq-Create" element={<AddFaq />}></Route>
            <Route exact path="/PrivacyPolicy" element={<Privacy />}></Route>
            <Route exact path="/PrivacyPolicy/PrivacyPolicy-Create" element={<AddPrivacy />}></Route>

            <Route exact path="/SetUp/Currency-List" element={<CurrencyList />}></Route>
            <Route exact path="/SetUp/Currency-Create" element={<AddCurrency />}></Route>
            <Route exact path="/SetUp/Location" element={<Location />}></Route>
            <Route exact path="/SetUp/Shipping" element={<Shipping />}></Route>
            <Route exact path="/SetUp/Tags" element={<Tags />}></Route>


            <Route exact path="/System-Setting/Maintenance-Mode" element={<Maintenance />}></Route>
            <Route exact path="/System-Setting/Analytics" element={<Analytics />}></Route>
            <Route exact path="/System-Setting/EmailSettings" element={<EmailSettings />}></Route>
            <Route exact path="/System-Setting/Setting" element={<Setting />}></Route>
            <Route exact path="/System-Setting/MailTemplates" element={<MailTemplates />}></Route>
            <Route exact path="/System-Setting/AddMailTemplate" element={<AddMailTemplate />}></Route>
            <Route exact path="/Admin-Reports/Orders" element={<Order />}></Route>
            <Route exact path="/Admin-Reports/Customers" element={<CustomerReports />}></Route>
            <Route exact path="/Subscriber" element={<Subscriber />}></Route>
            <Route exact path="/System-Setting/passwordChange" element={<PasswordChange />}></Route>



            <Route exact path="/requestEnquiry" element={<Enquiry />}></Route>


            <Route exact path="/bookAppointment" element={<BookAppointment />}></Route>
            <Route exact path="/productCustomization" element={<ProductCustomization />}></Route>
            <Route exact path="/quotes" element={<GetQuote />}></Route>

            {/* <Route exact path="/Banners" element={<Banners />}></Route> */}

            <Route exact path="/HomePageBanners" element={<HomeBanner />}></Route>
            <Route exact path="/HomePageBanners/HomeBanner-Create" element={<AddHomeBanner />}></Route>
            <Route exact path="/HomePageBanners/HomeBanner-Create/:id" element={<AddHomeBanner />}></Route>


          </Routes>
          <Footer />
        </div>
      </div>
    </section>
  );
}
