import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import {
  ContactInfoAdd,
  CONTACTINFOGet,
  SetContactInfoObj,
  ContactInfoUpdate,
  ContactInfoDelete,
} from "../../../redux/actions/ContactInfo/ContactInfo.action";
import { Box, Modal } from "@mui/material";

import { generateFilePath } from "../../Utility/utils";
import { getContactInfo } from "../../../services/contactInfo.service";

import { toastError } from "../../Utility/ToastUtils";

function ContactInfo() {
  const dispatch = useDispatch();
  const [selectedElement, setSelectedElement] = useState({});

  const [ModalBox, setModalBox] = useState(false);

  const contactInfoArr = useSelector((state) => state.contactInfo.contactInfos);
  const handleGet = () => {
    dispatch(CONTACTINFOGet());
  };
  const handleContactInfoDelete = (row) => {
    console.log(row, "des");
    dispatch(ContactInfoDelete(row));
  };

  useEffect(() => {
    handleGet();
  }, []);

  const handleEdit = (row) => {
    dispatch(SetContactInfoObj(row));
  };
  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Company name",
      selector: (row) => row.companyName,
    },
    {
      name: "Message",
      selector: (row) => row.message,
    },
    {
      name: "view",
      selector: (row) => (
        <CustomButton
          isBtn
          iconName="fa-solid fa-check"
          btnName="View"
          noIcon
          ClickEvent={() => {
            setSelectedElement(row);
            setModalBox(true);
          }}
        />
      ),
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => (
        <ActionIcon
          remove
          isRedirected={true}
          Uniquekey={row.id}
          deletePath="/ContactInfo"
          onDeleteClick={() => {
            handleContactInfoDelete(row._id);
          }}
        />
      ),
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Contact Info</h5>
                <div className="d-flex align-items-center gap-3">
                  {/* <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD CONTACT INFO"
                    path="/Contact-Info/Contact-Info-Create"
                    small
                    roundedPill
                  /> */}
                  {/* <SearchBox extraClass="bg-white" /> */}
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={brand_columns}
                  data={
                    contactInfoArr && contactInfoArr.length > 0
                      ? contactInfoArr
                      : []
                  }
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={ModalBox}
        onClose={() => setModalBox(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">

          <div className="modal-container" style={{ maxWidth: "80vw", minWidth: "50vw" }}>
            <div className="modal-header">
              <h5 style={{ color: "white", padding: "10px 15px", width: "80%" }}>Contact Details</h5>

              <CustomButton
                isBtn
                btntype="button"
                iconName="ion-close-circled text-white"
                changeClass="border-0 bg-transparent rounded-circle modal-close"
                ClickEvent={(e) => {
                  e.preventDefault();
                  setModalBox(false);
                }}
              />
            </div>
            <div className="modal-body m-0 p-0">

              <table class="table table-striped m-0 p-0">
                <tbody>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Name</td>
                    <td>{selectedElement?.name}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Email</td>
                    <td>{selectedElement?.email}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Company Name</td>
                    <td>{selectedElement?.companyName}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Message</td>
                    <td>{selectedElement?.message}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Modal>
    </main>
  );
}

export default ContactInfo;
