import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as CURRENCY from "../../actions/currency/Currency.action";

const initialState = {
  CurrencyArr: null,
  CurrencyObj: null,
  loading: false,
  error: null,
};

export const CurrencyReducer = (state = initialState, action) => {
  // console.log(action, "reduscer");
  switch (action.type) {
    case CURRENCY.CURRENCY_ADD:
      return {
        ...state,
        loading: true,
      };
    case CURRENCY.CURRENCY_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case CURRENCY.CURRENCY_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CURRENCY.GET_ALL_CURRENCY:
      return {
        ...state,
        loading: true,
      };
    case CURRENCY.GET_ALL_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        CurrencyArr: action.payload.data,
      };
    case CURRENCY.GET_ALL_CURRENCY_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CURRENCY.DELETE_CURRENCY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case CURRENCY.DELETE_CURRENCY_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CURRENCY.DELETE_CURRENCY_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CURRENCY.SET_CURRENCY_OBJ:
      return {
        ...state,
        loading: true,
      };
    case CURRENCY.SET_CURRENCY_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        CurrencyObj: action.payload.data,
        loading: false,
        error: null,
      };
    case CURRENCY.SET_CURRENCY_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CURRENCY.UPDATE_CURRENCY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case CURRENCY.UPDATE_CURRENCY_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CURRENCY.UPDATE_CURRENCY_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
