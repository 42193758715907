import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import {
  BLOGGet,
  blogDelete,
  SetblogObj,
} from "../../redux/actions/Blog/Blog.actions";
import { deleteById, get } from "../../services/requestEnquiry.service";
import { Modal, Box } from "@mui/material";
import { generateFilePath } from "../Utility/utils";
import { toastSuccess } from "../Utility/ToastUtils";


function Subscriber() {

  let dispatch = useDispatch();
  const [blogArr, setBlogArr] = useState([]);

  const [ModalBox, setModalBox] = useState(false);

  const [selectedElement, setSelectedElement] = useState({});


  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [zipCode, setZipCode] = useState("");


  const handleBlogEdit = (row) => {
    setEmail(row?.email)
    setPhone(row?.phone)
    setName(row?.name)
    setDestinationCountry(row?.destinationCountry)
    setZipCode(row?.zipCode)
  };






  const handleBlogGet = async () => {

    try {
      let query = ''
      const { data: res } = await get(query);
      console.log(res?.data, "12313131")
      setBlogArr(res?.data);
    } catch (error) {
      console.error(error);
    }
  };



  const handleBlogDelete = async (row) => {
    try {
      if (window.confirm("Are You sure you want to delete this data ?") == true) {
        const { data: res } = await deleteById(row._id);
        if (res.message) {
          toastSuccess(res.message);
          handleBlogGet();
        }
      }



    } catch (error) {
      console.error(error);
    }
  };


  // console.log(handleBlogGet(), "erwerwe");
  useEffect(() => {
    handleBlogGet();
  }, []);




  const blog_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Created On",
      selector: (row) => `${new Date(row.createdAt).toDateString()}`,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Image",
      cell: (row) => <a href={`${generateFilePath(row.image)}`} target="_blank"><img src={generateFilePath(row.image)} style={{ height: 70 }} alt="" /></a>,
    },
    {
      name: "phone no.",
      selector: (row) => row.phone,
    },
    {
      name: "name",
      selector: (row) => row.name,
    },
    {
      name: "Destination Country",
      selector: (row) => row.destinationCountry,
    },

    {
      name: "Zip Code",
      selector: (row) => row.zipCode,
    },
    {
      name: "view",
      selector: (row) => (

        <CustomButton
          isBtn
          iconName="fa-solid fa-check"
          btnName="View"
          noIcon
          ClickEvent={() => {
            setSelectedElement(row)
            setModalBox(true);
          }}
        />
      ),
    },
    {
      name: "Delete",
      selector: (row) => (

        <CustomButton
          isBtn
          iconName="fa-solid fa-check"
          btnName="Delete"
          noIcon
          ClickEvent={() => {
            handleBlogDelete(row)
          }}
        />
      ),
    },

  ];


  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Enquiry List</h5>

              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  // data={blog_data}
                  data={blogArr && blogArr.length > 0 ? blogArr : []}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>

      </section>
      <Modal
        open={ModalBox}
        onClose={() => setModalBox(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">

          <div className="modal-container" style={{ maxWidth: "80vw", minWidth: "50vw" }}>
            <div className="modal-header">
              <h5 style={{ color: "white", padding: "10px 15px", width: "80%" }}>Enquiry Details</h5>

              <CustomButton
                isBtn
                btntype="button"
                iconName="ion-close-circled text-white"
                changeClass="border-0 bg-transparent rounded-circle modal-close"
                ClickEvent={(e) => {
                  e.preventDefault();
                  setModalBox(false);
                }}
              />
            </div>
            <div className="modal-body m-0 p-0">
              <table class="table table-striped m-0 p-0">
                <tbody>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Name</td>
                    <td>{selectedElement?.name}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Phone Number</td>
                    <td>{selectedElement?.phone}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Email</td>
                    <td>{selectedElement?.email}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Country</td>
                    <td>{selectedElement?.destinationCountry} </td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Zip Code</td>
                    <td>{selectedElement?.zipCode}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}> <b>Quantity Required</b></td>
                    <td><b>{selectedElement?.indicators} pcs</b></td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Description 1</td>
                    <td>{selectedElement?.descriptionOne}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Description 2</td>
                    <td>{selectedElement?.dsecriptionTwo}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Image</td>
                    <td>
                      {
                        selectedElement?.imageArr && selectedElement?.imageArr.length > 0 && selectedElement?.imageArr.map((el, index) => {
                          return (
                            <div className="my-2">

                              <a href={`${generateFilePath(el.image)}`} target="_blank"><img src={generateFilePath(el.image)} style={{ height: 70 }} alt="" /></a>
                            </div>
                          )
                        })
                      }
                    </td>
                    {/* <td><a href={`${generateFilePath(selectedElement.image)}`} target="_blank"><img src={generateFilePath(selectedElement.image)} style={{ height: 70 }} alt="" /></a></td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Modal>

    </main>
  );
}

export default Subscriber;
