import React, { useState } from "react";
import PendingOrder from "./PendingOrder";
import ConfirmedOrder from "./ConfirmedOrder";
import CompleteOrder from "./CompleteOrder";
import PendingPaymentOrder from "./PendingPaymentOrder";
import CancleOrder from "./CancleOrder";
import InhouseOrder from "./InhouseOrder";
import CustomButton from "../../Utility/Button";
import tabClick from "../../Utility/TabClick";
import { rolesObj } from "../../../utils/roles";
import { useDispatch, useSelector } from "react-redux";
import { downloadAllOrder } from "../../../services/order.service";

import {url}from "../../../services/url.service"
import { toastError } from "../../Utility/ToastUtils";
const server=url+"/userOrder"


function TotalOrder() {

  const role = useSelector((state) => state.auth.role);


  const [tabList, settabList] = useState([
    {
      tabName: "Pending Orders",
      active: true,
      render: <PendingOrder name="Pending Orders" />,
    },
    // {
    //   tabName: "Confirmed Orders",
    //   active: false,
    //   render: <ConfirmedOrder name="Confirmed Orders" />,
    // },
    // {
    //   tabName: "Completed Orders",
    //   active: false,
    //   render: <CompleteOrder name="Completed Orders" />,
    // },
    // {
    //   tabName: "Pending Payment Orders",
    //   active: false,
    //   render: <PendingPaymentOrder name="Pending Payment Orders" />,
    // },
    // {
    //   tabName: "Refused/Cancelled Orders",
    //   active: false,
    //   render: <CancleOrder name="Refused/Cancelled Orders" />,
    // },
    // {
    //   tabName: "Inhouse Orders",
    //   active: false,
    //   render: <InhouseOrder name="Inhouse Orders" />,
    // },
  ]);

  const handleDownloadAllOrder=async()=>{
    try{

      window.open(`${server}/downloadAllOrder`)
    }catch(err){
      toastError(err)
    }
    // let get= await downloadAllOrder(role)

  }
  return (
    <main>
      <section className="total-order" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <ul className="flex-wrap total-order-tab align-items-center gap-2 dashboard-pills">

                {/* {tabList.map((item, i) => {
                  return (
                    <li key={i}>
                      <CustomButton
                        navPills
                        btnName={item.tabName}
                        pillActive={item.active ? true : false}
                        ClickEvent={() => tabClick(i, tabList, settabList)}
                      />
                    </li>
                  );
                })} */}
              </ul>
            </div>
            <div className="col-12" >
              <h5>Orders  &nbsp;&nbsp;


            { role == rolesObj.ADMIN&&
        <button style={{marginRight:"2px",borderRadius:"50px",backgroundColor: "#C59D69"}}type="button" onClick={()=>handleDownloadAllOrder()}>export csv</button>
      }
      </h5>
              <PendingOrder />
            </div>
            {/* {tabList.map((item, index) => {
              return (
                <div className="col-12" key={index}>
                  {item.active && item.render}
                </div>
              );
            })} */}
          </div>
        </div>
      </section>
    </main>
  )
}

export default TotalOrder;
