// PaymentGateway
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/currency";

export const add = (formData) => {
  console.log(formData, "inaxios");
  return axiosApiInstance.post(serverUrl + "/", formData);
};

export const get = () => {
  return axiosApiInstance.get(`${serverUrl}/`);
};
// console.log(get, "getina sxios");
export const deleteById = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const update = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
