import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/productQuantityRanges";

export const addproductQuantityRanges = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getproductQuantityRangess = (query) => {
    return axiosApiInstance.get(`${serverUrl}/?${query}`);
};

export const deleteproductQuantityRanges = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
export const updateproductQuantityRanges = (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
