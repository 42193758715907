import React, { useEffect, useState } from "react";
import { addproductQuantityRanges, updateproductQuantityRanges } from "../../../services/ProductQuantityRanges.service";
import CustomButton from "../../Utility/Button";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
export default function AddProductQuantityRanges({ selectedObj, setCount }) {

    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);

    const handleAdd = async () => {
        try {
            if (from == "" || from <= 0) {
                toastError("From cannot be less than equal to 0");
                return
            }
            if (to < 0) {
                toastError("To cannot be less than to 0");
                return
            }
            if (parseInt(from) >= parseInt(to) && parseInt(to) > 0) {
                toastError("To cannot be less than equal to from");
                return
            }
            let obj = {
                from
            };
            if (to && to != "" && to != null && to != undefined && to != 0) {
                obj.to = to
            }
            let res
            if (selectedObj.from) {
                res = await updateproductQuantityRanges(selectedObj._id, obj)

            }
            else {
                res = await addproductQuantityRanges(obj)
            }
            if (res.data.message) {
                toastSuccess(res.data.message)
                setCount(prev => prev + 1)
            }


        }
        catch (err) {
            toastError(err)
        }
    };


    useEffect(() => {
        if (selectedObj.from) {
            setFrom(selectedObj?.from ? selectedObj?.from : "")
            setTo(selectedObj?.to ? selectedObj?.to : "")
        }
        else {
            setFrom(0)
            setTo(0)
        }
    }, [selectedObj])

    return (
        <div>
            <form className="form row">
                <div className={"col-12"}>
                    <label className="blue-1 fs-12">
                        From <span className="red">*</span>
                    </label>
                    <input value={from} onChange={(event) => setFrom(event.target.value)} type="number" className="form-control" />
                </div>
                <div className={"col-12"}>
                    <label className="blue-1 fs-12">
                        To (0 will not be considered and (<b>from</b>) will be considered as maximum value in this case) <span className="red">*</span>
                    </label>
                    <input value={to} onChange={(event) => setTo(event.target.value)} type="number" className="form-control" />
                </div>

                <div className="col-12">
                    <CustomButton btntype="button" ClickEvent={handleAdd} iconName="fa-solid fa-check" btnName="Save" isBtn small={false} />
                </div>
            </form>
        </div>
    );
}

