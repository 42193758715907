import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as MAIL_TEMPLATE from "../../actions/MailTemplate/MailTemplate.actions";

const initialState = {
  mailTemplates: null,
  mailTemplatesObj: null,
  loading: false,
  error: null,
};

export const mailTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAIL_TEMPLATE.MAIL_TEMPLATE_ADD:
      return {
        ...state,
        loading: true,
      };
    case MAIL_TEMPLATE.MAIL_TEMPLATE_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case MAIL_TEMPLATE.MAIL_TEMPLATE_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MAIL_TEMPLATE.GET_ALL_MAIL_TEMPLATE:
      return {
        ...state,
        loading: true,
      };
    case MAIL_TEMPLATE.GET_ALL_MAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        mailTemplates: action.payload.data,
      };
    case MAIL_TEMPLATE.GET_ALL_MAIL_TEMPLATE_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MAIL_TEMPLATE.DELETE_MAIL_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case MAIL_TEMPLATE.DELETE_MAIL_TEMPLATE_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case MAIL_TEMPLATE.DELETE_MAIL_TEMPLATE_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MAIL_TEMPLATE.UPDATE_MAIL_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case MAIL_TEMPLATE.UPDATE_MAIL_TEMPLATE_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case MAIL_TEMPLATE.UPDATE_MAIL_TEMPLATE_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MAIL_TEMPLATE.SET_MAIL_TEMPLATE_OBJ:
      return {
        ...state,
        loading: true,
      };
    case MAIL_TEMPLATE.SET_MAIL_TEMPLATE_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        mailTemplatesObj: action.payload.data,
        loading: false,
        error: null,
      };
    case MAIL_TEMPLATE.SET_MAIL_TEMPLATE_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
