import {
  addBlogCategory,
  deleteBlogCategory,
  getBlogCategory,
  updateBlogCategory,
} from "../../../services/blogCategory.service";

export const BLOGCATEGORY_ADD = "BLOGCATEGORY_ADD";
// SetBlogCategoryObj;
export const BLOGCATEGORY_ADD_SUCCESS = "BLOGCATEGORY_ADD_SUCCESS";
export const BLOGCATEGORY_ADD_FAIL = "BLOGCATEGORY_ADD_FAIL";

export const GET_ALL_BLOGCATEGORY = "GET_ALL_BLOGCATEGORY";
export const GET_ALL_BLOGCATEGORY_SUCCESS = "GET_ALL_BLOGCATEGORY_SUCCESS";
export const GET_ALL_BLOGCATEGORY_FAIL = "GET_ALL_BLOGCATEGORY_FAIL";

export const UPDATE_BLOGCATEGORY_BY_ID = "UPDATE_BLOGCATEGORY_BY_ID";
export const UPDATE_BLOGCATEGORY_BY_ID_SUCCESS =
  "UPDATE_BLOGCATEGORY_BY_ID_SUCCESS";
export const UPDATE_BLOGCATEGORY_BY_ID_FAIL = "UPDATE_BLOGCATEGORY_BY_ID_FAIL";

export const SET_BLOGCATEGORY_OBJ = "SET_BLOGCATEGORY_OBJ";
export const SET_BLOGCATEGORY_OBJ_SUCCESS = "SET_BLOGCATEGORY_OBJ_SUCCESS";
export const SET_BLOGCATEGORY_OBJ_FAIL = "SET_BLOGCATEGORY_OBJ_FAIL";

export const GET_BLOGCATEGORY_BY_ID = "GET_BLOGCATEGORY_BY_ID";
export const GET_BLOGCATEGORY_BY_ID_SUCCESS = "GET_BLOGCATEGORY_BY_ID_SUCCESS";
export const GET_BLOGCATEGORY_BY_ID_FAIL = "GET_BLOGCATEGORY_BY_ID_FAIL";

export const DELETE_BLOGCATEGORY_BY_ID = "DELETE_BLOGCATEGORY_BY_ID";
export const DELETE_BLOGCATEGORY_BY_ID_SUCCESS =
  "DELETE_BLOGCATEGORY_BY_ID_SUCCESS";
export const DELETE_BLOGCATEGORY_BY_ID_FAIL = "DELETE_BLOGCATEGORY_BY_ID_FAIL";

export const blogCategoryAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: BLOGCATEGORY_ADD });
    console.log(formData, "formDatain redux action");
    let { data: response } = await addBlogCategory(formData);
    if (response) {
      console.log(response, "response12");
      dispatch({
        type: BLOGCATEGORY_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(BLOGCATEGORYGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: BLOGCATEGORY_ADD_FAIL, payload: err });
  }
};

export const BLOGCATEGORYGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_BLOGCATEGORY });
    let { data: response } = await getBlogCategory(formData);
    if (response) {
      console.log(response, "rspnse actin get");
      dispatch({
        type: GET_ALL_BLOGCATEGORY_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: BLOGCATEGORY_ADD_FAIL, payload: err });
  }
};

export const SetBlogCategoryObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_BLOGCATEGORY_OBJ });
    console.log(formData, "form action ");
    if (formData) {
      dispatch({
        type: SET_BLOGCATEGORY_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_BLOGCATEGORY_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_BLOGCATEGORY_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const BlogCategoryUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BLOGCATEGORY_BY_ID });
    console.log(formData, id, "id formdata");
    let { data: response } = await updateBlogCategory(formData, id);
    if (response) {
      console.log(response, "action update");
      dispatch({
        type: UPDATE_BLOGCATEGORY_BY_ID_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_BLOGCATEGORY_BY_ID_FAIL, payload: err });
  }
};

export const BLOGCATEGORYDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BLOGCATEGORY_BY_ID });
    let { data: response } = await deleteBlogCategory(id);
    if (response) {
      console.log(response, "deleted actn");
      dispatch({
        type: DELETE_BLOGCATEGORY_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(BLOGCATEGORYGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_BLOGCATEGORY_BY_ID_FAIL, payload: err });
  }
};
