import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
// import * as CONTACTINFO from "../../actions/ContactInfo/ContactInfo.actions";
import * as CONTACTINFO from "../../actions/ContactInfo/ContactInfo.action";

const initialState = {
  contactInfos: null,
  ContactInfoObj: null,
  loading: false,
  error: null,
};

export const contactInfoReducer = (state = initialState, action) => {
  // console.log(action, "reduscer");
  switch (action.type) {
    case CONTACTINFO.CONTACTINFO_ADD:
      return {
        ...state,
        loading: true,
      };
    case CONTACTINFO.CONTACTINFO_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case CONTACTINFO.CONTACTINFO_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONTACTINFO.GET_ALL_CONTACTINFO:
      return {
        ...state,
        loading: true,
      };
    case CONTACTINFO.GET_ALL_CONTACTINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        contactInfos: action.payload.data,
      };
    case CONTACTINFO.GET_ALL_CONTACTINFO_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONTACTINFO.DELETE_CONTACTINFO_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case CONTACTINFO.DELETE_CONTACTINFO_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CONTACTINFO.DELETE_CONTACTINFO_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONTACTINFO.SET_CONTACTINFO_OBJ:
      return {
        ...state,
        loading: true,
      };
    case CONTACTINFO.SET_CONTACTINFO_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        ContactInfoObj: action.payload.data,
        loading: false,
        error: null,
      };
    case CONTACTINFO.SET_CONTACTINFO_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONTACTINFO.UPDATE_CONTACTINFO_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case CONTACTINFO.UPDATE_CONTACTINFO_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CONTACTINFO.UPDATE_CONTACTINFO_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
