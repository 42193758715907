import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as PAYMENTGATEWAY from "../../actions/PaymentGateway/PaymentGateway.action";

const initialState = {
  PaymentGateways: null,
  PaymentGatewayObj: null,
  loading: false,
  error: null,
};

export const paymentGatewayReducer = (state = initialState, action) => {
  // console.log(action, "reduscer");
  switch (action.type) {
    case PAYMENTGATEWAY.PAYMENTGATEWAY_ADD:
      return {
        ...state,
        loading: true,
      };
    case PAYMENTGATEWAY.PAYMENTGATEWAY_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case PAYMENTGATEWAY.PAYMENTGATEWAY_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTGATEWAY.GET_ALL_PAYMENTGATEWAY:
      return {
        ...state,
        loading: true,
      };
    case PAYMENTGATEWAY.GET_ALL_PAYMENTGATEWAY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        PaymentGateways: action.payload.data,
      };
    case PAYMENTGATEWAY.GET_ALL_PAYMENTGATEWAY_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTGATEWAY.DELETE_PAYMENTGATEWAY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case PAYMENTGATEWAY.DELETE_PAYMENTGATEWAY_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case PAYMENTGATEWAY.DELETE_PAYMENTGATEWAY_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENTGATEWAY.SET_PAYMENTGATEWAY_OBJ:
      return {
        ...state,
        loading: true,
      };
    case PAYMENTGATEWAY.SET_PAYMENTGATEWAY_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        PaymentGatewayObj: action.payload.data,
        loading: false,
        error: null,
      };
    case PAYMENTGATEWAY.SET_PAYMENTGATEWAY_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PAYMENTGATEWAY.UPDATE_PAYMENTGATEWAY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case PAYMENTGATEWAY.UPDATE_PAYMENTGATEWAY_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case PAYMENTGATEWAY.UPDATE_PAYMENTGATEWAY_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
