import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { addNegotiations, getNegotiations } from "../../../services/negitiations.service";
import { getOrderById, updateOrderById, updateStatusOrderById } from "../../../services/order.service";
import { orderStatusObj } from "../../../utils/orderStatusObj";
import { toastError, toastSuccess } from "../../../utils/toastUtils";
import CustomButton from "../../Utility/Button";
import { ORDER_STATUS } from "../../Utility/constants";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { generateFilePath } from "../../Utility/utils";
import { useSelector } from "react-redux"
import logo from "../../../assets/images/logo.png"
import FileUpload from "../../Utility/FileUpload";
import { LoadingContext } from "../../../App";
function OrderDetail(props) {
  // =========================================================================================
  const userObj = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useContext(LoadingContext);




  const [handlingCharges, setHandlingCharges] = useState(0);


  const [searchParams, setSearchParams] = useSearchParams();
  const [trackingId, setTrackingId] = useState("");
  const [orderObj, setOrderObj] = useState({});
  const [selecetedOrderStatus, setSelecetedOrderStatus] = useState({});
  const [deliveryTypeArr, setDeliveryTypeArr] = useState([]);
  const [methodOfShipingArr, setMethodOfShipingArr] = useState([]);
  const [negotiationText, setNegotiationText] = useState("");

  const [negotiationArr, setNegotiationArr] = useState([]);


  const chatRef = useRef()

  const handleGetNegotiations = async () => {
    try {


      let { data: res } = await getNegotiations(searchParams.get("orderId"))
      if (res.message) {
        setNegotiationArr(res.data)
        chatRef.current.scrollIntoView({ behavior: "smooth" })
      }
    }
    catch (err) {
      toastError(err)
    }
  }






  const handleAddNegotiations = async () => {
    try {
      setLoading(true);
      if (negotiationText == "") {
        toastError("Please enter some text to !!")
        setLoading(false)
        return

      }

      let obj = {
        message: negotiationText,
        createdBy: userObj?._id,
        orderId: orderObj?._id
      }
      let { data: res } = await addNegotiations(obj)
      if (res.message) {
        setLoading(false)
        handleGetNegotiations()
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }


  const getOrder = async () => {
    try {
      const { data: res } = await getOrderById(searchParams.get("orderId"));
      if (res) {
        console.log(res.data);
        setOrderObj(res?.data);
        setTrackingId(res?.data?.trackingId);
        setSelecetedOrderStatus({ label: res?.data?.orderStatus, value: res?.data?.orderStatus });
        setDeliveryTypeArr(res?.data?.deliveryTypeArr)
        setHandlingCharges(res?.data?.handlingCharges)
        // setMethodOfShipingArr(res?.data?.methodOfShipingArr)
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };

  useEffect(() => {
    getOrder();
    handleGetNegotiations()
  }, []);

  const sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => <img src={generateFilePath(row?.image)} alt="" />,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },

    {
      name: "Qty",
      cell: (row) => <p className="fs-14">{row.quantity}</p>,
    },
    {
      name: "Price",
      selector: (row) => `₹ ${row?.price / row.quantity}`,
    },

    {
      name: "Total",
      selector: (row) => parseInt(row?.price),
    },
  ];

  const handlePrint = async () => {
    // try {
    //   const { data: res } = await getInvoiceById(orderObj?._id);
    //   if (res) {
    //     window.open(`${url}/${res.data}`);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleOrderUpdate = async () => {
    try {
      setLoading(true)
      let updateObj = {
        deliveryTypeArr,
        // methodOfShipingArr,
        handlingCharges,
        status: orderStatusObj?.WAITINGFORAPPROVAL
      };

      // console.log(updateObj, "updateObjupdateObjupdateObj")
      const { data: res } = await updateStatusOrderById(orderObj?._id, updateObj);
      if (res) {
        setLoading(false)
        toastSuccess(res.message);
        getOrder();
      }
    } catch (error) {
      setLoading(false)
      toastError(error);
    }
  };


  const handleUpdatedTrackingId = async () => {
    try {
      setLoading(true)
      if (!trackingId || trackingId == "") {
        toastError("Tracking Id is mandatory !!!");
        setLoading(false)
        return
      }



      let updateObj = {
        status: orderStatusObj?.SHIPPING,
        trackingId: trackingId,
      };

      console.log(updateObj, "updateObj")
      const { data: res } = await updateStatusOrderById(orderObj?._id, updateObj);
      if (res) {
        setLoading(false)
        toastSuccess(res.message);
        getOrder();
      }
    } catch (error) {
      setLoading(false)
      toastError(error);
    }
  };
  const handleMarkPaymentRecieved = async () => {
    try {
      setLoading(true)
      let paymentStatus = orderObj?.halfPayment ? ((orderObj.payment == orderStatusObj?.HALFPAYMENTRECIEVED) && orderObj?.imageArr.length == 2 ? orderStatusObj?.COMPLETEPAYMENTRECIEVED : orderStatusObj?.HALFPAYMENTRECIEVED) : (orderStatusObj?.COMPLETEPAYMENTRECIEVED)
      let updateObj = {
        status: orderStatusObj?.ORDERINPROCESS,
        payment: paymentStatus,
      };

      console.log(updateObj, "updateObjupdateObjupdateObj2222")
      const { data: res } = await updateStatusOrderById(orderObj?._id, updateObj);
      if (res) {
        setLoading(false)
        toastSuccess(res.message);
        getOrder();
      }
    } catch (error) {
      setLoading(false)
      toastError(error);
    }
  };


  const handleOrderComplete = async () => {
    try {
      setLoading(true)
      let updateObj = {
        status: orderStatusObj?.COMPLETED,
      };
      const { data: res } = await updateStatusOrderById(orderObj?._id, updateObj);
      if (res) {
        setLoading(false)
        toastSuccess(res.message);
        getOrder();
      }
    } catch (error) {
      setLoading(false)
      toastError(error);
    }
  };





  const handlePriceChange = (index, value) => {
    let tempArr = deliveryTypeArr

    tempArr[index].value = value


    setDeliveryTypeArr([...tempArr])

  }
  const handlePriceChangeFormethodOfShipingArr = (index, value) => {
    let tempArr = methodOfShipingArr

    tempArr[index].value = value

    setMethodOfShipingArr([...tempArr])

  }





  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="col-12 col-md-12">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5 className="blue-1 m-0">Order Details</h5>
              {/* <CustomButton ClickEvent={() => handlePrint()} path={`/Order/Sale-Detail?orderId=${orderObj?._id}`} isLink downloadAble btnName="PRINT" noIcon noIconMargin /> */}
            </div>
          </div>


          <div className="row">
            <DashboardBox className="col-12 col-md-12 row  m-0">






































































              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th rowSpan={3}><img style={{ width: 130 }} src={logo} alt="" /></th>
                    <td rowSpan={3} colSpan={3}>
                      <div>
                        <div><b>ARTISAGA PRIVATE LIMITED</b></div>
                        <div>2204, PELICAN, SKYLARK ENCLAVE, HIRANANDANI ESTATE,</div>
                        <div>Ghodbunder road, Thane west, Mumbai, Maharashtra - 400607</div>
                        <div><b>GSTIN: 27AAWCA7825Q1ZK</b></div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}><span> E-mail: info@artisaga.com</span> <span>Tel: +91 9833362013</span></div>
                      </div>
                    </td>
                    <td>Invoice  No.: {orderObj?.invoiceNo}</td>
                    <td>Dated: {new Date(orderObj?.createdAt).toDateString()}</td>
                    <td>Order Id : {orderObj?.orderId}</td>
                  </tr>
                  <tr>
                    <td>Buyer Name: {orderObj?.addressObj?.name}</td>
                    <td>Buyer Contact No.: {orderObj?.addressObj?.phone}</td>
                    <td>Buyer email : {orderObj?.addressObj?.email}</td>
                  </tr>
                  <tr>
                    <td>Country.: {orderObj?.addressObj?.country}</td>
                    <td>State: {orderObj?.addressObj?.state}</td>
                    <td>Town/  City : {orderObj?.addressObj?.city}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}></td>
                    <td colSpan={3}>Street Address: {orderObj?.addressObj?.address}</td>

                  </tr>
                  <tr>
                    <td colSpan={4}></td>
                    <td colSpan={3}>Pincode : {orderObj?.addressObj?.pincode}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Product Image</td>
                    <td style={{ fontWeight: "bold" }}>Product code</td>
                    <td style={{ fontWeight: "bold" }}>Product Name</td>
                    <td style={{ fontWeight: "bold" }}>Product Quantity</td>
                    <td style={{ fontWeight: "bold" }}>HSN Code</td>
                    <td style={{ fontWeight: "bold" }}>Price Per Unit.</td>
                    <td style={{ fontWeight: "bold" }}>Total Price</td>
                  </tr>
                  {
                    orderObj?.items && orderObj?.items.length > 0 && orderObj?.items.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td ><img src={generateFilePath(row?.image)} style={{ width: 100 }} alt="" /></td>
                          <td >{row?.sku}</td>
                          <td >{row.name}</td>
                          <td >{row.quantity}</td>
                          <td >{row?.hsn}</td>
                          <td >$ {row?.price / row.quantity}</td>
                          <td >$ {row?.price}</td>
                        </tr>
                      )
                    })
                  }
                  <tr>
                    <td colSpan={5}></td>
                    <td style={{ fontWeight: "bold" }}>Sub total value</td>
                    <td>{orderObj?.totalAmount}</td>
                  </tr>
                  <tr>
                    <td colSpan={5}></td>
                    <td style={{ fontWeight: "bold" }}>Packaging/Handling Charges</td>
                    <td><input value={handlingCharges} type="number" className="form-control" onChange={(e) => setHandlingCharges(e.target.value)} /></td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold", textTransform: "uppercase", textAlign: "end" }} colSpan={6}>Shipping Cost</td>
                    <td></td>
                  </tr>
                  {
                    deliveryTypeArr && deliveryTypeArr.length > 0 && deliveryTypeArr.map((el, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ fontWeight: "bold", textTransform: "uppercase", textAlign: "end" }} colSpan={6}>{el?.name}</td>
                          <td> <input type="number" value={el?.value} onChange={(e) => handlePriceChange(index, e.target.value)} className="form-control" /></td>
                        </tr>
                      )
                    })
                  }


                </tbody>
              </table>


              <div className="my-5">
                {
                  orderObj?.status != orderStatusObj?.SHIPPING && orderObj?.status != orderStatusObj?.COMPLETED &&
                  <CustomButton isBtn btntype="button" noIcon={true} ClickEvent={() => handleOrderUpdate()} iconName="fa-solid fa-check" extraClass={"me-4"} btnName="Save" />
                }
                {
                  (((orderObj?.imageArr?.length > 0)) && orderObj.status != orderStatusObj?.COMPLETED) &&
                  <CustomButton isBtn btntype="button" ClickEvent={() => handleOrderComplete()} iconName="fa-solid fa-check" extraClass={"me-4"} btnName="Mark Order Complete" />
                }
                {((orderObj) && (orderObj?.imageArr?.length > 0) && (orderObj?.payment != orderStatusObj?.COMPLETEPAYMENTRECIEVED)) &&
                  <CustomButton isBtn btntype="button" noIcon={true} ClickEvent={() => handleMarkPaymentRecieved()} iconName="fa-solid fa-check" btnName="Mark payment recieved" />
                }
              </div>

              <hr />

              {orderObj && orderObj?.imageArr?.length > 0 &&
                <div className="customer-profile my-5">
                  <h6 className="blue-1 text-capitalize mb-3">Copy of Payment</h6>
                  <ul className="blue-1 fs-14 d-flex">
                    {
                      orderObj?.imageArr && orderObj?.imageArr?.length > 0 && orderObj && orderObj?.imageArr.map((el, index) => {
                        return (
                          <a key={index} href={`${generateFilePath(el.image)}`} style={{ margin: 10 }} target="_blank">
                            {
                              el.image.includes("pdf") ?
                                <div style={{ border: "solid 1px grey", padding: "10px", borderRadius: 10 }}>View Payment file</div>
                                :
                                <img src={generateFilePath(el.image)} alt="" />
                            }
                          </a>
                        )
                      })
                    }



                  </ul>
                </div>
              }
              <hr />
              {
                (orderObj?.status != orderStatusObj?.COMPLETED || orderObj?.status != orderStatusObj?.CREATED) &&
                <>
                  <div className="col-12 col-md-12 my-5">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Tracking Details </label>
                        <br />
                        <br />
                        {
                          trackingId && trackingId.includes("base64") ?
                            <img src={trackingId && trackingId.includes("base64") ? trackingId : `${generateFilePath(trackingId)}`} alt="" style={{ width: 100 }} />
                            :

                            <a href={generateFilePath(trackingId)}>
                              <img src={trackingId && trackingId.includes("base64") ? trackingId : `${generateFilePath(trackingId)}`} alt="" style={{ width: 100 }} />
                            </a>
                        }
                        <br />
                        <br />
                        <FileUpload onFileChange={(v) => setTrackingId(v)} />
                      </div>
                      <div className="col-md-4 text-center mt-4">
                        <CustomButton isBtn btntype="button" ClickEvent={() => handleUpdatedTrackingId()} iconName="fa-solid fa-check" noIcon={true} btnName="Save tracking details" />
                      </div>
                    </div>
                  </div>
                  <hr />
                </>

              }

              {
                orderObj?.status != "CREATED" || orderObj?.payment != "PENDING" ?
                  <>
                    <div className=" border p-3 rounded">
                      <h6 className="blue-1 text-capitalize mb-3">Negotiations</h6>

                      <div className="chatContainer">
                        <ul className="chat-box" >
                          {
                            negotiationArr && negotiationArr.length > 0 && negotiationArr.map((el, index) => {
                              return (
                                <li className={el?.createdBy == userObj?._id ? "box reverse" : "box"} key={index}>
                                  {/* <img src={images.party} alt='' /> */}
                                  <div>
                                    <h6 className="lexend">{el?.createdBy == userObj?._id ? "You" : "User"}</h6>
                                    <p className="small">{el?.message}</p>
                                  </div>
                                </li>
                              )
                            })

                          }

                          <div style={{ marginTop: "145px" }} ref={chatRef}></div>
                        </ul>
                      </div>


                      <div className="d-flex row">
                        <div className="col-12 py-2">
                          <textarea type="text" className="form-control" onChange={(e) => setNegotiationText(e.target.value)} value={negotiationText} />
                        </div>
                        <div className="col-5 py-2">
                          <CustomButton isBtn btntype="button" ClickEvent={() => handleAddNegotiations()} iconName="fa-solid fa-check" btnName="Send" />
                          <CustomButton isBtn btntype="button" ClickEvent={() => handleGetNegotiations()} extraClass={"ms-2"} iconName="fa-solid fa-check" noIcon={true} btnName="Reload" />
                        </div>
                      </div>

                    </div>
                    <hr />
                  </>
                  :
                  <div style={{ marginTop: "0px" }} ref={chatRef}></div>
              }



            </DashboardBox>
          </div>
        </div>
      </section >
    </main >
  );
}

export default OrderDetail;
