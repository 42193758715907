import React, { useEffect, useState } from "react";
import { addSize, updateSize } from "../../../services/Size.service";
import CustomButton from "../../Utility/Button";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
export default function AddSize({ selectedObj, setCount }) {

    const [name, setName] = useState("");


    const handleAddCategory = async () => {
        try {
            if (name == "") {
                toastError("Name cannot be empty");
                return
            }
            let obj = {
                name,
            };
            let res
            if (selectedObj.name) {
                res = await updateSize(selectedObj._id, obj)

            }
            else {
                res = await addSize(obj)
            }
            if (res.data.message) {
                toastSuccess(res.data.message)
                setCount(prev => prev + 1)
            }


        }
        catch (err) {
            toastError(err)
        }
    };


    useEffect(() => {
        if (selectedObj.name) {
            setName(selectedObj?.name)
        }
        else {
            setName("")
        }
    }, [selectedObj])

    return (
        <div>
            <form className="form row">
                <div className={"col-12"}>
                    <label className="blue-1 fs-12">
                        Name <span className="red">*</span>
                    </label>
                    <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                </div>

                <div className="col-12">
                    <CustomButton btntype="button" ClickEvent={handleAddCategory} iconName="fa-solid fa-check" btnName="Save" isBtn small={false} />
                </div>
            </form>
        </div>
    );
}

