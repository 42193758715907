import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import ReactQuill from "react-quill"; // ES6
import FileUpload from "../Utility/FileUpload";
import QuillEditor from "../../utils/QuillEditor";
import { useSelector, useDispatch } from "react-redux";
import { BLOGCATEGORYGet } from "../../redux/actions/BlogCategory/BlogCategory.actions";
import { toastError } from "../../utils/toastUtils";

import {
  blogAdd,
  BLOGGet,
  blogUpdate,
  SetBlogObj,
  BLOGDelete,
} from "../../redux/actions/Blog/Blog.actions";

import { tagGet } from "../../redux/actions/Tag/Tag.actions";
import { generateFilePath } from "../Utility/utils";

function AddBlog() {
  let dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState({});
  const [tags, setTags] = useState("");
  const [author, setAuthor] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [image, setImage] = useState("");
  const [prevBlogId, setPrevBlogId] = useState(null);
  const [publish, setPublish] = useState(false);
  const [isUpdateBlog, setIsUpdateBlog] = useState(false);
  // console.log(category, "categorydf");
  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  //   { value: "vanilla1", label: "Vanillaone" },
  //   { value: "vanilla2", label: "Vanillatwo" },
  // ];
  const options = [];
  const blogObj = useSelector((state) => state.blog.BLOGObj);
  const blogCategoryArr = useSelector(
    (state) => state.blogCategory.blogCategories
  );
  if (blogCategoryArr) {
    for (let el of blogCategoryArr) {
      options.push({ value: el.name, label: el.name });
    }
  }
  // console.log(blogCategoryArr, "in add blog here");
  // console.log(blogObj, "blogObj  34");
  useEffect(() => {
    if (blogObj) {
      setTitle(blogObj?.title);
      setSlug(blogObj?.slug);
      setDescription(blogObj?.description);
      setShortDescription(blogObj?.shortDescription);
      setImage(blogObj?.image);
      setTags(blogObj?.tags)
      setPublish(blogObj.publish);
      setAuthor(blogObj.author)
      setPrevBlogId(blogObj?._id);
      if (blogObj?.blogCategoryId) {
        let categoryArr = blogCategoryArr && blogCategoryArr.filter(el => el._id == blogObj?.blogCategoryId);
        if (categoryArr.length == 1) {
          setCategory({ value: categoryArr[0]?.name, label: categoryArr[0]?.name });
        }

      }

      setIsUpdateBlog(true);
    }
  }, [blogObj]);

  const handleBlogCategoryGet = () => {
    dispatch(BLOGCATEGORYGet());
  };
  useEffect(() => {
    handleBlogCategoryGet();
  }, []);
  // const handleTagGet = () => {
  //   dispatch(tagGet());
  // };
  // useEffect(() => {
  //   handleTagGet();
  // }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (`${title}` == '') {
      toastError('Title is mandatory');
      return
    }

    if (`${slug}` == '') {
      toastError('Slug is mandatory');
      return
    }

    if (`${description}` == '') {
      toastError('Description is mandatory');
      return
    }
    if (`${author}` == '') {
      toastError('Duthor is mandatory');
      return
    }
    if (`${shortDescription}` == '') {
      toastError('Short description is mandatory');
      return
    }

    if (`${image}` == '') {
      toastError('Tmage is mandatory');
      return
    }


    let obj = {
      title,
      slug,
      description,
      shortDescription,
      tags,
      image,
      author,
      publish,
    };

    if (blogObj?._id) {
      dispatch(blogUpdate(prevBlogId, obj));
    } else {
      dispatch(blogAdd(obj));
    }
  };

  const handleFileSet = (value) => {
    setImage(value);
  };
  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <form action="#" className="form">
            <h5 className="blue-1 mb-4">
              {isUpdateBlog ? "Update" : "Add New "} Blog
            </h5>
            <div className="row gy-4 gy-xxl-0">
              <div className="col-12 col-xxl-8">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Blog Info</h5>
                    <div className="col-12 mb-3">
                      <label>
                        TITLE <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        AUTHOR <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        SLUG<span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        SHORT DESCRIPTION<span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={shortDescription}
                        onChange={(e) => setShortDescription(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        DESCRIPTION<span className="red">*</span>
                      </label>
                      {/* <ReactQuill
                        theme="snow"
                        value={description}
                        onChange={(e) => setDescription(e)}
                      /> */}
                      {/* <ReactQuill /> */}
                      <QuillEditor theme="snow"
                        value={description}
                        handleChange={setDescription} />
                    </div>
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12 col-xxl-4">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Basic Info</h5>
                    <div className="col-12 mb-3">
                      <label>
                        Tags (Comma Separated)<span className="red">*</span>
                      </label>
                      <input
                        value={tags}
                        onChange={(event) => setTags(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                      {/* <Select
                        options={options}
                        isMulti
                        onChange={(e) => setTags(e)}
                      /> */}

                      <div className="form-text fs-12 blue-1">
                        Suggested Tags
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        Image(1000x500)px<span className="red">*</span>
                      </label>
                      <br />
                      <br />
                      <img src={image.includes("base64") ? image : `${generateFilePath(image)}`} style={{ height: 100 }} alt="" />
                      <br />
                      <br />
                      <FileUpload onFileChange={handleFileSet} />
                    </div>
                    {/* 
                    <div className="col-12">
                      <label>Status</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input checked={status} onChange={() => setStatus(!status)} className="form-check-input" type="checkbox" name="category-status" value="option1" id="active-banner" />
                        <label className="form-check-label fs-14" htmlFor="active-banner">
                          Active
                        </label>
                      </div>
                    </div>
                     */}

                    <div className="col-12 mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="publish-checkbox"
                          checked={publish}
                          onChange={() => setPublish(!publish)}
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="publish-checkbox"
                        >
                          Publish
                        </label>
                        {/* <input checked={publish} onChange={() => setPublish(!publish)} className="form-check-input" type="checkbox" name="category-status" value="option1" id="active-banner" /> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <CustomButton
                        isBtn
                        iconName="fa-solid fa-check"
                        btnName="Save"
                        ClickEvent={handleSubmit}
                      />
                    </div>
                  </div>
                </DashboardBox>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddBlog;
