// 

import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/quote";

export const getQuote = () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteQuoteById = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

