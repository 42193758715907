import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import {
  tagGet,
  TAGDelete,
  SetTAGObj,
  TAGAdd,
  TAGUpdate,
} from "../../../redux/actions/Tag/Tag.actions";

function Tags() {
  const tagArray = useSelector((state) => state.tag.tags);
  const tagObject = useSelector((state) => state.tag.tagObj);
  const [prevTagId, setPrevTagId] = useState(null);
  const [name, setName] = useState("");
  // const dispatch = use;
  let dispatch = useDispatch();
  const handleTagGet = () => {
    dispatch(tagGet());
  };
  // console.log(handleTagGet(), "handleTagGet");
  useEffect(() => {
    handleTagGet();
  }, []);

  useEffect(() => {
    if (tagObject) {
      setName(tagObject?.name);
      setPrevTagId(tagObject?._id);
    }
  }, [tagObject]);

  const handleTagDelete = (row) => {
    console.log(row, "des");
    dispatch(TAGDelete(row));
  };
  const handleTagEdit = (row) => {
    // console.log(row, "des");
    dispatch(SetTAGObj(row));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      name,
    };

    if (tagObject?._id) {
      console.log(obj, "drt");
      dispatch(TAGUpdate(prevTagId, obj));
    } else {
      dispatch(TAGAdd(obj));
    }
    // dispatch(BlogCategoryUpdate(prevBlogCategoryId, obj));
    // } else {
    // dispatch(blogCategoryAdd(obj));
    // }
  };
  const tag_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => row.name,
    },
    // {
    //   name: "Action",
    //   minWidth: "210px",
    //   maxWidth: "211px",
    //   cell: (row) => <ActionIcon Uniquekey={row.id} remove edit />,
    // },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcon
          remove
          edit
          // detail
          // detailpath="/Blog/View-Post"
          Uniquekey={row.id}
          deletePath="/SetUp/Tags"
          onDeleteClick={() => {
            handleTagDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            handleTagEdit(row);
          }}
          editPath="/SetUp/Tags"
        />
      ),
      width: "25%",
    },
  ];

  // const tag_data = [
  //   {
  //     id: "1",
  //     sl: "1",
  //     Name: "Nails",
  //   },
  //   {
  //     id: "2",
  //     sl: "2",
  //     Name: "Eyes",
  //   },
  //   {
  //     id: "3",
  //     sl: "3",
  //     Name: "Face",
  //   },
  //   {
  //     id: "4",
  //     sl: "4",
  //     Name: "Lips",
  //   },
  //   {
  //     id: "5",
  //     sl: "5",
  //     Name: "Nail polish",
  //   },
  //   {
  //     id: "6",
  //     sl: "6",
  //     Name: "Perfect Finish box(Nail P...",
  //   },
  //   {
  //     id: "7",
  //     sl: "7",
  //     Name: "Foundation",
  //   },
  //   {
  //     id: "8",
  //     sl: "8",
  //     Name: "LIQUID SINDOOR",
  //   },
  //   {
  //     id: "9",
  //     sl: "9",
  //     Name: "BEAUTY POP BOX LIP COLOR",
  //   },
  //   {
  //     id: "10",
  //     sl: "10",
  //     Name: "LIPSTIC A & B",
  //   },
  // ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="blue-1 mb-4">Add Tag</h5>
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12">
                    <label className="blue-1">
                      Name <span className="red">*</span>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </label>
                    {/* <input type="text" className="form-control" /> */}
                  </div>
                  {/* <div className="col-12 mt-2">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                    />
                  </div> */}
                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex gap-3 justify-content-between mb-3 align-items-center">
                <h5 className="blue-1 m-0">Tags List</h5>
                <SearchBox extraClass="bg-white" />
              </div>
              <DashboardTable>
                <DataTable
                  columns={tag_columns}
                  data={tagArray && tagArray.length > 0 ? tagArray : []}
                  // data={tag_data}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Tags;
