import React, { useEffect } from "react";
import { images } from "../Images/Images";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

function SideBar({ style, className }) {
  let role = useSelector((state) => state.auth.role);

  let location = useLocation();


  const [sidebar_item, setsidebar_item] = useState([
    {
      isrotated: true,
      active: true,
      name: "dashboard",
      roleArr: ["ADMIN"],
      path: "/",
      icon: "ion-grid",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "products",
      path: "/Dashboard",
      icon: "fa-brands fa-product-hunt",
      roleArr: ["ADMIN"],
      children: [
        {
          name: "Category",
          roleArr: ["ADMIN"],
          path: "/Product/Category",
          active: false,
        },
        {
          name: "Color",
          roleArr: ["ADMIN"],
          path: "/Product/View-Color",
          active: false,
        },
        {
          name: "Material",
          roleArr: ["ADMIN"],
          path: "/Product/View-Material",
          active: false,
        },
        {
          name: "Size",
          roleArr: ["ADMIN"],
          path: "/Product/View-Size",
          active: false,
        },
        {
          name: "Product Quantity Ranges",
          roleArr: ["ADMIN"],
          path: "/Product/View-Product-Quantity-Ranges",
          active: false,
        },
        {
          name: "Category-Sequence",
          path: "/Product/Category-Sequence",
          roleArr: ["ADMIN"],
          active: false,
        },
        // {
        //   name: "Brand",
        //   path: "/Product/Brand",
        //   roleArr: ["ADMIN"],
        //   active: false,
        // },
        // {
        //   name: "Bulk Product Upload",
        //   path: "/Product/Bulk-Product-Upload",
        //   roleArr: ["ADMIN"],
        //   active: false,
        // },
        {
          name: "Product List",
          path: "/Product/Product-List",
          roleArr: ["ADMIN"],
          active: false,
        },
        {
          name: "Product Review",
          path: "/Review/Product-Review",
          roleArr: ["ADMIN"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "blog",
      path: "/Dashboard",
      icon: "fa-brands fa-product-hunt",
      roleArr: ["ADMIN"],
      children: [
        {
          name: "Blog",
          roleArr: ["ADMIN"],
          path: "/Blog/post",
          active: false,
        },
        // {
        //   name: "Blog Category",
        //   path: "/Blog/Category",
        //   roleArr: ["ADMIN"],
        //   active: false,
        // },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Order Manage",
      path: "/Dashboard",
      roleArr: ["ADMIN"],
      icon: "ion-android-cart",
      children: [
        {
          name: "Total Order",
          path: "/Order/Total-Order",
          roleArr: ["ADMIN"],
          active: false,
        },
      ],
    },

    {
      isrotated: false,
      active: false,
      name: "Users",
      path: "/Dashboard",
      icon: "fa-solid fa-users",
      roleArr: ["ADMIN", "SUBADMIN"],
      children: [
        {
          name: "All Users",
          path: "/User-list",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Customer KYC",
          path: "/Customer/KYC",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Refund Manage",
      path: "/",
      icon: "ion-android-cart",
      children: [
        {
          name: "Refund Requests",
          path: "/Refund-Manage/Refund-Request",
          active: false,
        },
        {
          name: "Reasons",
          path: "/Refund-Manage/Reason",
          active: false,
        },
        {
          name: "Refund Process",
          path: "/Refund-Manage/Refund-Process",
          active: false,
        },
        {
          name: "Configuration",
          path: "/Refund-Manage/Refund-Config",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Frontend CMS",
      path: "/Dashboard",
      roleArr: ["ADMIN", "SUBADMIN"],
      icon: "fa-solid fa-user",
      children: [
        {
          name: "Banners",
          path: "/Banners",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Home Page Banners",
          path: "/HomePageBanners",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Our certifications",
          path: "/Gallery",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Testimonial",
          path: "/Testimonial",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Team",
          path: "/Team",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Setting",
          path: "/Setting",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Review",
      roleArr: ["SELLER", "SUBADMIN"],
      path: "/Dashboard",
      icon: "fa-solid fa-user",
      children: [
        {
          roleArr: ["SELLER", "SUBADMIN"],
          name: "Product Review",
          path: "/Review/Product-Review",
          active: false,
        },
        {
          roleArr: ["SELLER", "SUBADMIN"],
          name: "Company Review ",
          path: "/Review/Seller-Review",
          active: false,
        },
        {
          roleArr: ["SELLER", "SUBADMIN"],
          name: "Review Configuration",
          path: "/Review/Review-Configuration",
          active: false,
        },
      ],
    },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Setup",
    //   roleArr: ["ADMIN"],
    //   path: "/Dashboard",
    //   icon: "ion-settings",
    //   children: [
    //     {
    //       name: "Currency List",
    //       path: "/SetUp/Currency-List",
    //       roleArr: ["ADMIN"],
    //       active: false,
    //     },
    //     {
    //       name: "Shipping",
    //       path: "/SetUp/Shipping",
    //       roleArr: ["ADMIN"],
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Coupon",
    //   roleArr: ["ADMIN"],
    //   path: "/Dashboard",
    //   icon: "fa-solid fa-gift",
    //   children: [
    //     {
    //       name: "Coupon",
    //       path: "/Coupon/Coupon",
    //       roleArr: ["ADMIN"],
    //       active: false,
    //     },
    //   ],
    // },

    {
      isrotated: false,
      active: false,
      name: "System Setting",
      roleArr: ["ADMIN"],
      path: "/Dashboard",
      icon: "fa-solid fa-cog",
      children: [
        // {
        //   name: "Email Templates",
        //   path: "/System-Setting/MailTemplates",
        //   roleArr: ["ADMIN"],
        //   active: false,
        // },
        {
          name: "Maintenance Mode",
          path: "/System-Setting/Maintenance-Mode",
          roleArr: ["ADMIN"],
          active: false,
        },
        {
          name: "Analytics",
          path: "/System-Setting/Analytics",
          roleArr: ["ADMIN"],
          active: false,
        },
        {
          name: "EmailSettings",
          path: "/System-Setting/EmailSettings",
          roleArr: ["ADMIN"],
          active: false,
        },
        {
          name: "Password change",
          path: "/System-Setting/passwordChange",
          roleArr: ["ADMIN"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Admin Reports",
      roleArr: ["ADMIN"],
      path: "/Dashboard",
      icon: "fa-solid fa-book",
      children: [
        {
          name: "Orders",
          path: "/Admin-Reports/Orders",
          roleArr: ["ADMIN"],
          active: false,
        },
        {
          name: "Customers",
          path: "/Admin-Reports/Customers",
          roleArr: ["ADMIN"],
          active: false,
        },
      ],
    },

    {
      isrotated: true,
      active: false,
      name: `Request Enquiry `,
      roleArr: ["ADMIN"],
      path: "/requestEnquiry",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: true,
      active: false,
      name: `Book Appointment `,
      roleArr: ["ADMIN"],
      path: "/bookAppointment",
      icon: "fa-solid fa-user",
      children: [],
    },

    {
      isrotated: true,
      active: false,
      name: `Product Customization `,
      roleArr: ["ADMIN"],
      path: "/productCustomization",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: true,
      active: false,
      name: `Quotes `,
      roleArr: ["ADMIN"],
      path: "/quotes",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: true,
      active: false,
      name: `Contact Enquiry`,
      roleArr: ["ADMIN"],
      path: "/ContactInfo",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: true,
      active: false,
      name: `Newsletter Subscribers `,
      roleArr: ["ADMIN"],
      path: "/Subscriber",
      icon: "fa-solid fa-user",
      children: [],
    },
  ]);

  const isRotating = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.isrotated = !el.isrotated;
        el.active = true;
      } else {
        el.active = false;
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const childActive = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (el.children && el.children.length > 0) {
        el.children.map((item, childIndex) => {
          if (childIndex === i) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  return (
    <div
      id="sidebar"
      style={{ backgroundColor: "#c7c7c7" }}
      className={`${className ? className : ""}`}
    >
      <div
        className="main-logo"
        style={style ? { padding: "26px 10px", height: "auto" } : {}}
      >
        {style ? (
          <img src={images?.favicon} alt="" />
        ) : (
          <img src={images?.logo} alt="" />
        )}
      </div>
      <ul className="sidebar-menu" id="sidebarMenu">
        {sidebar_item &&
          sidebar_item?.map((item, i) => {
            if (
              typeof array === "undefined" &&
              item?.children &&
              item?.children?.length === 0 &&
              item?.roleArr.some(
                (el) => `${el}`.toLowerCase() == `${role}`.toLowerCase()
              )
            ) {
              return (
                <li key={`sidebar_item_${i}`}>
                  <Link
                    to={item?.path}
                    className={item?.active ? "active" : ""}
                    onClick={() => isRotating(i)}
                  >
                    <i className={item?.icon}></i>
                    {!style && <p className="mb-0">{item?.name}</p>}
                  </Link>
                </li>
              );
            } else {
              if (
                item?.roleArr &&
                item?.roleArr?.length > 0 &&
                item?.roleArr.some(
                  (el) => `${el}`.toLowerCase() == `${role}`.toLowerCase()
                )
              ) {
                return (
                  <li key={`sidebar_item_${i}`}>
                    <Link
                      to={`#sidebar_item_children_${i}`}
                      className={
                        item?.active || location === item?.path ? "active" : ""
                      }
                      data-bs-toggle="collapse"
                      aria-expanded={item?.active}
                      aria-controls={`sidebar_item_children_${i}`}
                      role="button"
                      onClick={() => isRotating(i)}
                    >
                      <i className={item.icon}></i>
                      {!style && (
                        <p className="mb-0">
                          {item?.name}
                          {item?.isrotated ? (
                            <i className="ion-arrow-up-b pe-3"></i>
                          ) : (
                            <i className="ion-arrow-down-b pe-3"></i>
                          )}
                        </p>
                      )}
                    </Link>
                    {!style && (
                      <ul
                        className="collapse"
                        id={`sidebar_item_children_${i}`}
                        data-bs-parent="#sidebarMenu"
                      >
                        {item?.children?.map((child, index) => {
                          if (
                            child?.roleArr &&
                            child?.roleArr?.length > 0 &&
                            child?.roleArr?.some(
                              (el) =>
                                `${el}`?.toLowerCase() ==
                                `${role}`?.toLowerCase()
                            )
                          ) {
                            return (
                              <li key={`${child?.name}_${index}`}>
                                <Link
                                  to={child?.path}
                                  className={
                                    child?.active || location === child?.path
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => childActive(index)}
                                >
                                  {child?.name}
                                </Link>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </li>
                );
              }
            }
          })}
      </ul>
    </div>
  );
}

export default SideBar;
