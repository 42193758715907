import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/gallery";

export const addGallery = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getGallery = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getGallery?${query}`);
};

export const updateGallery = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/deleteById/${id}`, formData);
};
