import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import FileUpload from "../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { SETTEAMOBJ, TEAMADD, TEAMUPDATE } from "../../redux/actions/Team/Team.actions";
function AddTeam({makeChange}) {
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [post, setpost] = useState();
    const [teamId, setTeamId] = useState("")
    const [status, setStatus] = useState(generalModelStatuses.APPROVED);


    const teamObj = useSelector((state) => state.team.teamObj);
    const handleAddCategory = () => {
        let obj = {
            name,
            description,
            status,
            image:imageStr
        };
        console.log(obj, "category obj",teamId);

        if (teamObj?._id) {
            dispatch(TEAMUPDATE(teamId, obj));
            dispatch(SETTEAMOBJ(null))
        } else {
            dispatch(TEAMADD(obj));
        }
    };
    const [imageStr, setImageStr] = useState("");

    const handleFileSet = (value) => {
      // console.log(value);
      setImageStr(value);
    };
    useEffect(() => {
        if (teamObj) {
            setName(teamObj?.name);
            setpost(teamObj?.post);
            setDescription(teamObj?.description);
            setStatus(teamObj?.status);
            setTeamId(teamObj?._id)
            setImageStr(teamObj.image);
        }

        // return () => {
        //     dispatch(SETTEAMOBJ(null));
        // };
    }, [teamObj]);

    // useEffect(() => {
    //     dispatch(CATEGORYGet());
    // }, []);



    return (
        <div className={makeChange ? "makeChange" : ""}>
            <form className="form row">
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Name <span className="red">*</span>
                    </label>
                    <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                </div>

                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Description <span className="red">*</span>
                    </label>
                    <textarea className="form-control" value={description} onChange={(event) => setDescription(event.target.value)}> </textarea>
                </div>
                <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Post <span className="red">*</span>
                    </label>
                    <input value={post} onChange={(event) => setpost(event.target.value)} type="text" className="form-control" />
                </div>
                <div className="col-12">
                      <label>image (150x150)PX</label>
                      <FileUpload onFileChange={handleFileSet} />
                    </div>
                <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
                    <label className="blue-1 fs-12">Status</label>
                    <div className="d-flex">
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" checked={status == generalModelStatuses.APPROVED} onClick={() => setStatus(generalModelStatuses.APPROVED)} type="radio" />
                            <label className="form-check-label fs-14" htmlFor="category-Radio1">
                                Active
                            </label>
                        </div>
                        <div className="form-check form-check-inline d-flex align-items-center">
                            <input className="form-check-input" type="radio" checked={status == generalModelStatuses.DECLINED} onClick={() => setStatus(generalModelStatuses.DECLINED)} />
                            <label className="form-check-label fs-14" htmlFor="category-Radio2">
                                Inactive
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <CustomButton btntype="button" ClickEvent={handleAddCategory} iconName="fa-solid fa-check" btnName="Save" isBtn small={makeChange ? true : false} />
                </div>
            </form>
        </div>
    );
}

export default AddTeam;
