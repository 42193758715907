import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/bookAppointment";

export const add = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const get = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getBookAppointment?${query}`);
};

export const deleteById = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

// export const updateTeam = (id, formData) => {
//     return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
// };

