import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/team";

export const addTeams = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getTeams = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getTeams?${query}`);
};

export const deleteTeam = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateTeam = (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

