import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/area";

export const addAreas = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getAreas = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getAreas?${query}`);
};

export const deleteArea = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateArea = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

