import Loader from "./components/Loader"
import axios from "axios";
import { createContext, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/scss/main.css";
import { onMessageListener } from "./firebase";
import { persistor, Store } from "./redux/store";
import RootRouter from "./routes/RootRouter";
import { toastSuccess } from "./utils/toastUtils";
export const axiosApiInstance = axios.create();
export const LoadingContext = createContext();
function App() {
  const [loading, setLoading] = useState(false);
  onMessageListener()
    .then((payload) => {
      // setNotification({title: payload.notification.title, body: payload.notification.body})
      // setShow(true);
      // console.log(payload);
      // console.log("Received background message ", payload);

      // const notificationTitle = payload.notification.title;
      // const notificationOptions = {
      //   body: payload.notification.body,
      // };
      toastSuccess(payload?.data?.description);
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <LoadingContext.Provider value={[loading, setLoading]}>
      <Provider store={Store}>
        <PersistGate loading={<h1>Loading...</h1>} persistor={persistor}>
          <Loader />
          <RootRouter />
          <Toaster />
        </PersistGate>
      </Provider>
    </LoadingContext.Provider>
  );
}

export default App;
