import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { addBanner, getById ,updateBanner} from "../../../services/Homebanner.service";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
import { generateFilePath } from "../../Utility/utils";



function AddHomeBanner() {

  const dispatch = useDispatch();
  const params = useParams();

  const [name, setName] = useState("");
  const [btnText, setBtnText] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [imageStr, setImageStr] = useState("");
  const [isUpdateBanner, setIsUpdateBanner] = useState(false);
  const [selectedBannerId, setselectedBannerId] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [status, setStatus] = useState(false);
  const [position, setPosition] = useState("left");
  // const bannerObj = useSelector((state) => state.banner.bannerObj);
   const [bannerObj,setBannerObj] =useState({})


  const handleFileSet = (value) => {
    // console.log(value);
    setImageStr(value);
  };

  const handleUpdateData = async (id) => {
    try {

      let { data: get } = await getById(id);
      // console.log(get?.data, "getget123");
      setBannerObj(get?.data)
    } catch (err) {
      toastError(err)
    }
  };


  useEffect(() => {
    // console.log(params, "params");
    if (params?.id?.length>4) {
      handleUpdateData(params.id);
    }
  }, [params]);


  useEffect(() => {
    if (bannerObj&&bannerObj._id) {
      setselectedBannerId(bannerObj?._id);
      setImageStr(bannerObj?.image);
      setPrevImage(bannerObj?.image);
      setStatus(bannerObj?.isActive);
      // console.log(bannerObj,"bannerobj");
      // setSelectedStatus({ value: brandObj.statusInfo, label: brandObj.statusInfo });
      setIsUpdateBanner(true);
    }
    return () => {
      // dispatch(SetBANNERObj(null));
    };
  }, [bannerObj]);

  const handleSubmit = async () => {
    try {
      if (imageStr == "") {
        toastError("Image is mandatory");
        return;
      }
      let obj = {
          isActive:status,
        image: imageStr ? imageStr : prevImage,
      };
      console.log(obj, "object21");
      // console.log(selectedBannerId, "selectedBannerId");
      if (isUpdateBanner) {
        const {data:get} =await updateBanner(obj,selectedBannerId)
        toastSuccess(get?.message);        
        console.log(get,"updateupdate2")
      } else {
        let { data: get } = await addBanner(obj);
        console.log(get?.message,"get?.data?.message")
        toastSuccess(get?.message);
      }
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">Add Home Branner</h5>
          <form action="#" className="form">
            <div className="row gy-4 gy-xxl-0">
              <div className="col-12 col-xxl-4 mb-0">
                <DashboardBox>
                  <div className="row">
                    {/* <h5 className="blue-1 mb-4">Status Info</h5> */}
                    <div className="col-12">
                      <label>Banner (1380x778)PX or similar pixel ratio</label>
                      <br />
                      <br />
                      <img
                        src={
                          `${imageStr}`.includes("base64")
                            ? imageStr
                            : generateFilePath(imageStr)
                        }
                        style={{ height: 80 }}
                      />
                      <br />
                      <br />

                      <FileUpload onFileChange={handleFileSet} />
                    </div>
                    <div className="col-12">
                      {/* <label>Status</label> */}
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          checked={status}
                          onChange={() => setStatus(!status)}
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="active-banner"
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="active-banner"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <CustomButton
                        btntype="button"
                        ClickEvent={handleSubmit}
                        isBtn
                        iconName="fa-solid fa-check"
                        btnName="Save"
                      />
                    </div>
                  </div>
                </DashboardBox>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddHomeBanner;
