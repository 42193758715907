import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { deleteQuoteById, getQuote } from "../../services/quote.service";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";

export default function GetQuote() {
    let dispatch = useDispatch();
    const [blogArr, setBlogArr] = useState([]);
    const [ModalBox, setModalBox] = useState(false);

    const [selectedElement, setSelectedElement] = useState({});




    const handleBlogGet = async () => {
        try {
            let query = "";
            const { data: res } = await getQuote(query);
            console.log(res?.data, "1231313res?.data");
            setBlogArr(res?.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        handleBlogGet();
    }, []);


    // const handleBlogEdit = (row) => {
    //   dispatch(SetblogObj(row));
    // };

    const blog_columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "5%",
        },
        {
            name: "Client Name",
            selector: (row) => `${row?.userObj?.firstName} ${row?.userObj?.lastName}`,
        },
        {
            name: "Product Image",
            cell: (row) => <img style={{ height: 50 }} src={generateFilePath(row?.productObj?.imageArr[0]?.image)} />
        },
        {
            name: "Product SKU",
            selector: (row) => row?.productObj?.sku,
        },
        {
            name: "Pin code",
            selector: (row) => row?.pincode,
        },
        {
            name: "view",
            selector: (row) => (
                <CustomButton
                    isBtn
                    iconName="fa-solid fa-check"
                    btnName="View"
                    noIcon
                    ClickEvent={() => {
                        setSelectedElement(row);
                        setModalBox(true);
                    }}
                />
            ),
        },
        {
            name: "Delete",
            selector: (row) => (
                <CustomButton
                    isBtn
                    iconName="fa-solid fa-check"
                    btnName="Delete"
                    noIcon
                    ClickEvent={() => {
                        handleBlogDelete(row)
                        // setModalBox(true);
                    }}
                />
            ),
        },
        // {
        //   name: "view",
        //   selector: (row) => (
        //     <button
        //       onClick={() => {
        //         handleBlogEdit(row);
        //         setModalBox(true);
        //       }}
        //     >
        //       view
        //     </button>
        //   ),
        // },
    ];



    const handleBlogDelete = async (row) => {
        try {
            if (window.confirm("Are You sure you want to delete this data ?") == true) {
                const { data: res } = await deleteQuoteById(row._id);
                if (res.message) {
                    toastSuccess(res.message);
                    handleBlogGet();
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <main>
            <section className="product-category" style={{ minHeight: "75vh" }}>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h5 className="blue-1 m-0">Quote List</h5>
                                {/* <button onClick={()=>setModalBox(true)}>view</button> */}
                            </div>
                            <DashboardTable>
                                <DataTable
                                    columns={blog_columns}
                                    // data={blog_data}
                                    data={blogArr && blogArr.length > 0 ? blogArr : []}
                                    pagination
                                />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Modal open={ModalBox} 
      onClose={() => setModalBox(false)} 
      aria-labelledby="modal-modal-title" 
      aria-describedby="modal-modal-description">
        <Box className="modal-box">
          <div className="modal-container" style={{ maxWidth: width ? width : "1000px" }}>
            <div className="modal-header">
              <h5>{name}</h5>
              <CustomButton
                isBtn
                btntype="button"
                iconName="ion-close-circled text-white"
                changeClass="border-0 bg-transparent rounded-circle modal-close"
                ClickEvent={(e) => {
                  e.preventDefault();
                  setModalBox(false);
                }}
              />
            </div>
            <div className="modal-body">
              {ModalType === "product-detail" && <ProductDetail customerData={data} />}
              {ModalType === "customer-detail" && <UserDetailModal customerData={data} />}
              </div>
          </div>
        </Box>
      </Modal> */}

            <Modal
                open={ModalBox}
                onClose={() => setModalBox(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-box">

                    <div className="modal-container" style={{ maxWidth: "80vw", minWidth: "50vw" }}>
                        <div className="modal-header">
                            <h5 style={{ color: "white", padding: "10px 15px", width: "80%" }}>Quote Details</h5>

                            <CustomButton
                                isBtn
                                btntype="button"
                                iconName="ion-close-circled text-white"
                                changeClass="border-0 bg-transparent rounded-circle modal-close"
                                ClickEvent={(e) => {
                                    e.preventDefault();
                                    setModalBox(false);
                                }}
                            />
                        </div>
                        <div className="modal-body m-0 p-0">

                            <table class="table table-striped m-0 p-0">
                                <tbody>
                                    <tr>
                                        <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Name</td>
                                        <td>{selectedElement?.userObj?.firstName} {selectedElement?.userObj?.lastName}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Email</td>
                                        <td>{selectedElement?.userObj?.email}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Phone</td>
                                        <td>{selectedElement?.userObj?.phone}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Product Image</td>
                                        <td><img style={{ height: 200 }} src={generateFilePath(selectedElement?.productObj?.imageArr[0]?.image)} /> </td>
                                    </tr>
                                    <tr>
                                        <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Product Name</td>
                                        <td>{selectedElement?.productObj?.name}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Product SKU</td>
                                        <td>{selectedElement?.productObj?.sku}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Quantity</td>
                                        <td>{selectedElement?.quantity}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>PinCode</td>
                                        <td>{selectedElement?.pincode} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* <div className="modal-container" style={{ maxWidth: "1000px" }}>
              <div className="modal-header">
                <div className="modal-container" style={{ maxWidth: "1000px" }}>
                  <h5 style={{ color: "white" }}>asdad</h5>
  
                  <CustomButton
                    isBtn
                    btntype="button"
                    iconName="ion-close-circled text-white"
                    changeClass="border-0 bg-transparent rounded-circle modal-close"
                    ClickEvent={(e) => {
                      e.preventDefault();
                      setModalBox(false);
                    }}
                  />
                </div>
              </div>
              <div className="modal-body">
                <div>size: {size}</div>
                <div>quantity: {quantity}</div>
                <div>pinCode: {pinCode}</div>
                <div>commment: {commment}</div>
              </div>
            </div> */}
                </Box>
            </Modal>
        </main >
    );
}