import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import SearchBox from "../../Utility/SearchBox";

import { deleteMaterial, getMaterials } from "../../../services/Material.service";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
import AddMaterial from "./AddMaterial";
export default function ViewMaterial() {
    // ==============================================================================================================
    const [displayTestimonialArr, setDisplayTestimonialArr] = useState([]);
    const [query, setQuery] = useState("");
    const [materialArr, setTestimonialMainArr] = useState([]);
    const [selectedObj, setSelectedObj] = useState({});
    const [count, setCount] = useState(0);

    const handleGet = async () => {
        try {
            let { data: res } = await getMaterials()
            if (res.message) {
                setDisplayTestimonialArr(res.data)
                setTestimonialMainArr(res.data)
                toastSuccess(res.message)
            }
        }
        catch (err) {
            toastError(err)
        }
    };

    const handleDelete = async (row) => {
        try {



            let confirm = window.confirm("Do you really want to delete this item?")
            if (confirm) {

                let { data: res } = await deleteMaterial(row._id);

                if (res.message) {
                    toastSuccess(res.message)
                    handleGet()
                }



            }
        }
        catch (err) {
            toastError(err)
        }

    }

    useEffect(() => {
        handleGet()
    }, [count])

    const category_columns = [
        {
            name: "ID",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row?.name,
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => <ActionIcon Uniquekey={row._id} remove edit deletePath="/Product/View-Material" onDeleteClick={() => handleDelete(row)} isRedirected={true} onEditClick={() => setSelectedObj(row)} editPath="/Product/View-Material" />,
        },
    ];

    // ==============================================================================================================

    const handleFilterByQuery = (e, requiredParametersArr) => {
        let tempArr = displayTestimonialArr.filter(el => {
            for (const ele of requiredParametersArr) {
                console.log(`${el[ele]}`.toLowerCase().includes(`${e}`.toLowerCase()), "ele,el")
                if (`${el[`${ele}`.toLowerCase()]}`.toLowerCase().includes(`${e}`.toLowerCase())) {
                    // console.log("true")
                    return true;
                }
                else {
                    return false;
                }
            }
        })
        setQuery(e)
        setTestimonialMainArr([...tempArr])
        console.log([...tempArr], "...tempArr")
    }


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">Material</h5>
                                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
                            </div>
                            <DashboardBox>
                                <AddMaterial selectedObj={selectedObj} setCount={setCount} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h5 className="blue-1 m-0">Material List</h5>
                                <div className="d-flex gap-3 align-items-center">
                                    {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                                    <SearchBox setQuery={(e) => { handleFilterByQuery(e, ["name"]); }} query={query} extraClass="bg-white" />
                                </div>
                            </div>
                            <DashboardTable>
                                <DataTable columns={category_columns} data={materialArr && materialArr.length > 0 ? materialArr : []} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}