import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import PendingOrder from "../Order/TotalOrder/PendingOrder";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { getAllOrders } from "../../services/order.service";
import { ORDER_STATUS } from "../Utility/constants";
import moment from "moment/moment";
import { orderStatusObj } from "../../utils/orderStatusObj";


function Order() {

    const [orders, setOrders] = useState([]);
    const [search, setSearch] = useState("")
    const [status, setStatus] = useState("Order")
    const [paymentStatus, setPaymentStatus] = useState("Order");
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const getOrder = async () => {
        try {
            let query = ''
            if (status && status != "Order") {
                query += `status=${status}`;
            }
            if (status && status != "Order" && paymentStatus && paymentStatus != "Order") {
                query += `&paymentStatus=${paymentStatus}`;
            }
            if (status && status == "Order" && paymentStatus && paymentStatus != "Order") {
                query += `paymentStatus=${paymentStatus}`;
            }
            if (search) {
                query += `&q=${search}`;
            }

            if (startDate) {
                query += `&startDate=${startDate}`;
            }
            if (endDate) {
                query += `&endDate=${endDate}`;
            }


            const { data: res } = await getAllOrders(query);
            if (res) {
                setOrders(res.data.filter((el) => el.status != "CANCELLED"));
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getOrder();
    }, []);
    useEffect(() => {
        getOrder();
    }, [search, status, startDate, endDate, paymentStatus]);

    const PendingOrder_columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Date",
            selector: (row) => new Date(row?.createdAt).toDateString(),
        },
        {
            name: "Order ID",
            selector: (row) => row?.orderId,
        },
        {
            name: "Phone",
            selector: (row) => row?.addressObj?.phone,
        },

        {
            name: "Total Amount",
            selector: (row) => row.totalAmount,
        },
        {
            name: "Order Status",
            button: true,
            width: "20%",
            cell: (row) => <CustomButton greenBtn={row?.status} btnName={row?.status} />,
        },
        {
            name: "Is Paid",
            button: true,
            width: "20%",
            cell: (row) => <CustomButton greenBtn={`${row.payment == orderStatusObj.HALFPAYMENTRECIEVED ? "50% balance pending" : row.payment == orderStatusObj.COMPLETEPAYMENTRECIEVED ? "Complete payment recieved" : "100% pending"}`} btnName={`${row.payment == orderStatusObj.HALFPAYMENTRECIEVED ? "50% balance pending" : row.payment == orderStatusObj.COMPLETEPAYMENTRECIEVED ? "Complete payment recieved" : "100% pending"}`} />,
        },
        {
            name: "Action",
            cell: (row) => <CustomButton btnName={"View"} path={`/Order/Order-Detail?orderId=${row?._id}`} isLink />,
        },
    ];

    return (
        <main>
            <section className="total-order" style={{ minHeight: "75vh" }}>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="dashboard-box">
                                <div className="d-flex gap-3 justify-content-between mb-4">
                                    <h5 className="blue-1 m-0">Orders</h5>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex gap-3 justify-content-between mb-4">
                                            <div className="w-100">
                                                <label>
                                                    Status
                                                </label>
                                                <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)} >
                                                    <option value="Order"> All Orders</option>
                                                    <option value={orderStatusObj?.CREATED}> Created</option>
                                                    <option value={orderStatusObj?.REJECTED}> Rejected</option>
                                                    <option value={orderStatusObj?.SHIPPING}> Shipping</option>
                                                    <option value={orderStatusObj?.ORDERINPROCESS}> Order In Process</option>
                                                    <option value={orderStatusObj?.COMPLETED}> Completed</option>
                                                </select>
                                            </div>
                                            <div className="w-100">
                                                <label>
                                                    Payment Status
                                                </label>
                                                <select className="form-control" value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)} >
                                                    <option value="Order"> All Orders</option>
                                                    <option value={"PENDING"}>100% pending</option>
                                                    <option value={orderStatusObj?.HALFPAYMENTRECIEVED}> 50% balance pending</option>
                                                    <option value={orderStatusObj?.COMPLETEPAYMENTRECIEVED}> Complete payment recieved</option>
                                                </select>
                                            </div>
                                            <div className="w-100 d-flex gap-3 justify-content-between">
                                                <div className="w-100">
                                                    <label>
                                                        Start Date
                                                    </label>
                                                    <input type="date" className="form-control" value={moment(startDate).format('YYYY-MM-DD')} onChange={(e) => setStartDate(e.target.value)} />
                                                </div>
                                                <div className="w-100">
                                                    <label>
                                                        End Date
                                                    </label>
                                                    <input type="date" className="form-control" value={moment(endDate).format('YYYY-MM-DD')} onChange={(e) => setEndDate(e.target.value)} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" >
                            <DashboardTable className="mt-4">
                                <div className="d-flex gap-3 justify-content-between mb-4">
                                    <h5 className="blue-1 m-0">Orders</h5>
                                    <div className="search-field">
                                        <form action="#" className="form">
                                            <div
                                                className="input-group"
                                            >
                                                <div className="input-group-text">
                                                    <i className="ion-ios-search-strong blue-1"></i>
                                                </div>
                                                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <DataTable columns={PendingOrder_columns} data={orders} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Order;
