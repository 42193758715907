import {
  add,
  deleteById,
  get,
  update,
} from "../../../services/currency.service";

export const CURRENCY_ADD = "CURRENCY_ADD";
export const CURRENCY_ADD_SUCCESS = "CURRENCY_ADD_SUCCESS";
export const CURRENCY_ADD_FAIL = "CURRENCY_ADD_FAIL";

export const GET_ALL_CURRENCY = "GET_ALL_CURRENCYS";
export const GET_ALL_CURRENCY_SUCCESS = "GET_ALL_CURRENCYS_SUCCESS";
export const GET_ALL_CURRENCY_FAIL = "GET_ALL_CURRENCYS_FAIL";

export const UPDATE_CURRENCY_BY_ID = "UPDATE_CURRENCY_BY_ID";
export const UPDATE_CURRENCY_BY_ID_SUCCESS = "UPDATE_CURRENCY_BY_ID_SUCCESS";
export const UPDATE_CURRENCY_BY_ID_FAIL = "UPDATE_CURRENCY_BY_ID_FAIL";

export const SET_CURRENCY_OBJ = "SET_CURRENCY_OBJ";
export const SET_CURRENCY_OBJ_SUCCESS = "SET_CURRENCY_OBJ_SUCCESS";
export const SET_CURRENCY_OBJ_FAIL = "SET_CURRENCY_OBJ_FAIL";

export const GET_CURRENCY_BY_ID = "GET_CURRENCY_BY_ID";
export const GET_CURRENCY_BY_ID_SUCCESS = "GET_CURRENCY_BY_ID_SUCCESS";
export const GET_CURRENCY_BY_ID_FAIL = "GET_CURRENCY_BY_ID_FAIL";

export const DELETE_CURRENCY_BY_ID = "DELETE_CURRENCY_BY_ID";
export const DELETE_CURRENCY_BY_ID_SUCCESS = "DELETE_CURRENCY_BY_ID_SUCCESS";
export const DELETE_CURRENCY_BY_ID_FAIL = "DELETE_CURRENCY_BY_ID_FAIL";

export const currencyAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CURRENCY_ADD });
    console.log(formData, "form raction ");
    let { data: response } = await add(formData);
    if (response) {
      console.log(response, "response raction ");
      dispatch({
        type: CURRENCY_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(currencyGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: CURRENCY_ADD_FAIL, payload: err });
  }
};

export const currencyGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CURRENCY });
    let { data: response } = await get(formData);
    if (response) {
      // console.log(response, "action get  ");
      dispatch({
        type: GET_ALL_CURRENCY_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_CURRENCY_FAIL, payload: err });
  }
};

export const SetcurrencyObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_CURRENCY_OBJ });
    if (formData) {
      // console.log(formData, "formdAtaSetobj action");
      dispatch({
        type: SET_CURRENCY_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_CURRENCY_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_CURRENCY_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const currencyUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CURRENCY_BY_ID });
    console.log(formData, "formData", id, "id");
    let { data: response } = await update(formData, id);
    if (response) {
      console.log(response, "res");
      dispatch({
        type: UPDATE_CURRENCY_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(currencyGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_CURRENCY_BY_ID_FAIL, payload: err });
  }
};

export const currencyDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CURRENCY_BY_ID });
    let { data: response } = await deleteById(id);
    if (response) {
      console.log(response, "response daelete action ");
      dispatch({
        type: DELETE_CURRENCY_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(currencyGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_CURRENCY_BY_ID_FAIL, payload: err });
  }
};
