import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/tag";

export const addTag = (formData) => {
  return axiosApiInstance.post(serverUrl + "/createTag", formData);
};

export const getTag = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getTag?${query}`);
};

export const deleteTag = (id) => {
  console.log(id, "id in service");
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateTag = (id, formData) => {
  console.log(id, "odi axios");
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
