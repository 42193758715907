import {
  addCoupon,
  deleteCoupon,
    getCoupons,
    updateCoupon,
  } from "../../../services/coupon.service";
  
  export const COUPON_ADD = "COUPON_ADD";
  // SetCOUPONObj;
  export const COUPON_ADD_SUCCESS = "COUPON_ADD_SUCCESS";
  export const COUPON_ADD_FAIL = "COUPON_ADD_FAIL";
  
  export const GET_ALL_COUPON = "GET_ALL_COUPON";
  export const GET_ALL_COUPON_SUCCESS = "GET_ALL_COUPON_SUCCESS";
  export const GET_ALL_COUPON_FAIL = "GET_ALL_COUPON_FAIL";
  
  export const UPDATE_COUPON_BY_ID = "UPDATE_COUPON_BY_ID";
  export const UPDATE_COUPON_BY_ID_SUCCESS =
    "UPDATE_COUPON_BY_ID_SUCCESS";
  export const UPDATE_COUPON_BY_ID_FAIL = "UPDATE_COUPON_BY_ID_FAIL";
  
  export const SET_COUPON_OBJ = "SET_COUPON_OBJ";
  export const SET_COUPON_OBJ_SUCCESS = "SET_COUPON_OBJ_SUCCESS";
  export const SET_COUPON_OBJ_FAIL = "SET_COUPON_OBJ_FAIL";
  
  export const GET_COUPON_BY_ID = "GET_COUPON_BY_ID";
  export const GET_COUPON_BY_ID_SUCCESS = "GET_COUPON_BY_ID_SUCCESS";
  export const GET_COUPON_BY_ID_FAIL = "GET_COUPON_BY_ID_FAIL";
  
  export const DELETE_COUPON_BY_ID = "DELETE_COUPON_BY_ID";
  export const DELETE_COUPON_BY_ID_SUCCESS =
    "DELETE_COUPON_BY_ID_SUCCESS";
  export const DELETE_COUPON_BY_ID_FAIL = "DELETE_COUPON_BY_ID_FAIL";
  
  export const CouponAdd = (formData) => async (dispatch) => {
    try {
      dispatch({ type: COUPON_ADD });
      console.log(formData, "formDatain redux action");
      let { data: response } = await addCoupon(formData);
      if (response) {
        console.log(response, "response12");
        dispatch({
          type: COUPON_ADD_SUCCESS,
          payload: response.message,
        });
        dispatch(CouponGet());
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: COUPON_ADD_FAIL, payload: err });
    }
  };
  
  export const CouponGet = (formData) => async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_COUPON });
      let { data: response } = await getCoupons(formData);
      if (response) {
        console.log(response, "rspnse actin get");
        dispatch({
          type: GET_ALL_COUPON_SUCCESS,
          payload: { data: response.data, message: response.message },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: COUPON_ADD_FAIL, payload: err });
    }
  };
  
  export const SetCouponObj = (formData) => async (dispatch) => {
    try {
      dispatch({ type: SET_COUPON_OBJ });
      console.log(formData, "form action ");
      if (formData) {
        dispatch({
          type: SET_COUPON_OBJ_SUCCESS,
          payload: { data: formData },
        });
      } else {
        dispatch({
          type: SET_COUPON_OBJ_SUCCESS,
          payload: { data: null },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: SET_COUPON_OBJ_FAIL,
        payload: { message: "NOT FOUND" },
      });
    }
  };
  
  export const CouponUpdate = (formData, id) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_COUPON_BY_ID });
      console.log(formData, id, "id formdata");
      let { data: response } = await updateCoupon(formData, id);
      if (response) {
        dispatch({
          type: UPDATE_COUPON_BY_ID_SUCCESS,
          payload: response.message,
        });
        
        dispatch(CouponGet());
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: UPDATE_COUPON_BY_ID_FAIL, payload: err });
    }
  };
  
  export const CouponDelete = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_COUPON_BY_ID });
      let { data: response } = await deleteCoupon(id);
      if (response) {
        console.log(response, "deleted actn");
        dispatch({
          type: DELETE_COUPON_BY_ID_SUCCESS,
          payload: response.message,
        });
        dispatch(CouponGet());
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: DELETE_COUPON_BY_ID_FAIL, payload: err });
    }
  };
  