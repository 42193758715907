import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { BANNERAdd, BANNERUpdate, SetBANNERObj } from "../../../redux/actions/Banner/Banner.actions";
import { GALLERYADD } from "../../../redux/actions/Gallery/Gallery.actions";

function AddGalleryImage() {
    const dispatch = useDispatch();

    const [imageUrl, setImageUrl] = useState("");

    const handleFileSet = (value) => {
        // console.log(value);
        setImageUrl(value);
    };


    const handleSubmit = () => {
        let obj = {
            imageUrl,
        };
        dispatch(GALLERYADD(obj));
    };
    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <h5 className="blue-1 mb-4">Add New Gallery Image</h5>
                    <form action="#" className="form">
                        <div className="row">
                            <div className="col-12 col-md-8 mb-0">
                                <DashboardBox>
                                    <div className="row">
                                        <h5 className="blue-1 mb-4">Image</h5>
                                        <div className="col-12">
                                            {/* (150x150)PX */}
                                            <label>Gallery Image </label>
                                            <FileUpload onFileChange={handleFileSet} />
                                        </div>
                                        <div className="col-12 mt-2">
                                            <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
                                        </div>
                                    </div>
                                </DashboardBox>
                            </div>

                        </div>
                    </form>
                </div>
            </section>
        </main>
    );
}

export default AddGalleryImage;
