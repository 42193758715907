import {
  addTag,
  deleteTag,
  getTag,
  updateTag,
} from "../../../services/tag.service";

export const TAG_ADD = "TAG_ADD";
export const TAG_ADD_SUCCESS = "TAG_ADD_SUCCESS";
export const TAG_ADD_FAIL = "TAG_ADD_FAIL";

export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const GET_ALL_TAGS_SUCCESS = "GET_ALL_TAGS_SUCCESS";
export const GET_ALL_TAGS_FAIL = "GET_ALL_TAGS_FAIL";

export const UPDATE_TAG_BY_ID = "UPDATE_TAG_BY_ID";
export const UPDATE_TAG_BY_ID_SUCCESS = "UPDATE_TAG_BY_ID_SUCCESS";
export const UPDATE_TAG_BY_ID_FAIL = "UPDATE_TAG_BY_ID_FAIL";

export const SET_TAG_OBJ = "SET_TAG_OBJ";
export const SET_TAG_OBJ_SUCCESS = "SET_TAG_OBJ_SUCCESS";
export const SET_TAG_OBJ_FAIL = "SET_TAG_OBJ_FAIL";

export const GET_TAG_BY_ID = "GET_TAG_BY_ID";
export const GET_TAG_BY_ID_SUCCESS = "GET_TAG_BY_ID_SUCCESS";
export const GET_TAG_BY_ID_FAIL = "GET_TAG_BY_ID_FAIL";

export const DELETE_TAG_BY_ID = "DELETE_TAG_BY_ID";
export const DELETE_TAG_BY_ID_SUCCESS = "DELETE_TAG_BY_ID_SUCCESS";
export const DELETE_TAG_BY_ID_FAIL = "DELETE_TAG_BY_ID_FAIL";

export const TAGAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: TAG_ADD });
    let { data: response } = await addTag(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: TAG_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(tagGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: TAG_ADD_FAIL, payload: err });
  }
};

export const tagGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_TAGS });
    let { data: response } = await getTag(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: GET_ALL_TAGS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: TAG_ADD_FAIL, payload: err });
  }
};

export const SetTAGObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_TAG_OBJ });
    if (formData) {
      dispatch({
        type: SET_TAG_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_TAG_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_TAG_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const TAGUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TAG_BY_ID });
    let { data: response } = await updateTag(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_TAG_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(tagGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_TAG_BY_ID_FAIL, payload: err });
  }
};

export const TAGDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TAG_BY_ID });
    let { data: response } = await deleteTag(id);
    if (response) {
      console.log(response);
      dispatch({
        type: DELETE_TAG_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(tagGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_TAG_BY_ID_FAIL, payload: err });
  }
};
