import React, { useState, useEffect } from "react";
import CustomButton from "../../Utility/Button";
import FileUpload from "../../Utility/FileUpload";
import { DashboardBox } from "../../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import {
  currencyAdd,
  currencyUpdate,
  SetcurrencyObj,
} from "../../../redux/actions/currency/Currency.action";
function AddCurrency() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [symbol, setSymbol] = useState("");
  const [convertRate, setConvertRate] = useState("");
  const [image, setImage] = useState("");
  const [isUpdateCurrency, setIisUpdateCurrency] = useState(false);
  const [currencyId, setCurrencyId] = useState("");
  const currencyObj = useSelector((state) => state.currency.CurrencyObj);

  useEffect(() => {
    if (currencyObj) {
      setName(currencyObj.name);
      setCode(currencyObj.code);
      setSymbol(currencyObj.symbol)
      setImage(currencyObj.image)
      setConvertRate(currencyObj.convertRate)
      setIisUpdateCurrency(true);
      setCurrencyId(currencyObj._id);
    }
    return () => {
      dispatch(SetcurrencyObj(null));
    };
  }, [currencyObj]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let obj = {
        name,
        code,
        symbol,
        convertRate,
        image
      };
      if (isUpdateCurrency) {
        dispatch(currencyUpdate(obj, currencyId));
      } else {
        dispatch(currencyAdd(obj));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileSet = (value) => {
    setImage(value);
  }

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">
            {isUpdateCurrency ? "Update" : "Add New "} Currency
          </h5>
          <DashboardBox className="col-12 col-md-8">
            <form action="#" className="form row">
              <div className="col-12">
                <label>
                  NAME<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label>
                  CODE <span className="red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label>
                  SYMBOL<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                />
              </div>
              <div className="col-12 mb-3">
                      <label>
                        Country Flag Logo<span className="red">*</span>
                      </label>
                      <FileUpload onFileChange={handleFileSet} />
                    </div>
              <div className="col-12">
                <label>
                  CONVERT RATE 1 INR = ?<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={convertRate}
                  onChange={(e) => setConvertRate(e.target.value)}
                />
              </div>
              <div className="col-12 mt-2">
                <CustomButton
                  isBtn
                  iconName="fa-solid fa-check"
                  btnName="Save"
                  ClickEvent={handleSubmit}
                />
              </div>
            </form>
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default AddCurrency;
