import React, { useEffect, useState } from 'react'
import { getSystemSetting, updateSystemSetting } from '../../services/system.service';
import { toastError, toastSuccess } from '../../utils/toastUtils';
import CustomButton from '../Utility/Button'
import { DashboardBox } from '../Utility/DashboardBox'
import FileUpload from "../Utility/FileUpload";
import { generateFilePath } from '../Utility/utils';

const Analytics = () => {

    const [trackingId, setTrackingId] = useState("")
    const [pixelId, setPixelId] = useState("")
    const [googleEnable, setGoogleEnable] = useState(false)
    const [facebookEnable, setFacebookEnable] = useState(false)
    const [systemSetting, setSystemSetting] = useState([])
    const [systemSettingId, setSystemSettingId] = useState("")
    const handleGetAllUsers = async () => {
        try {
            const { data: res } = await getSystemSetting();
            setSystemSetting(res?.data[0]);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        handleGetAllUsers();
    }, []);

    useEffect(() => {
        if (systemSetting) {
            console.log(systemSetting?.googleAnalytics)
            console.log(systemSetting?.facebookAnalytics)
            setGoogleEnable(systemSetting?.googleAnalytics?.status);
            setFacebookEnable(systemSetting.facebookAnalytics?.status);
            setTrackingId(systemSetting.googleAnalytics?.trackingId);
            setPixelId(systemSetting.facebookAnalytics?.trackingId);
            setSystemSettingId(systemSetting._id)

        }
    }, [systemSetting]);

    const handleGoogleAnalyticSubmit = async (e) => {
        e.preventDefault();
        if (`${trackingId}` == '') {
            toastError('trackingId is mandatory');
            return
        }

        console.log(trackingId)


        let obj = {
            googleAnalytics: {
                trackingId: trackingId,
                status: googleEnable,
            },
        }
        try {
            const { data: res } = await updateSystemSetting(systemSettingId, obj);
            toastSuccess(res?.message);
            handleGetAllUsers();
        } catch (error) {
            toastError(error)
        }
    };

    const handleFacebookAnalyticSubmit = async (e) => {
        e.preventDefault();


        if (`${pixelId}` == '') {
            toastError('pixel Id is mandatory');
            return
        }

        let obj = {
            facebookAnalytics: {
                trackingId: pixelId,
                status: facebookEnable,
            },
        }
        try {
            const { data: res } = await updateSystemSetting(systemSettingId, obj)
            toastSuccess(res?.message);

            handleGetAllUsers();
        } catch (error) {
            toastError(error)
        }
    };


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="blue-1 mb-4">Google Analytics  </h5>
                            <DashboardBox>
                                <form action="" className="form row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between">
                                            <div className="form-check form-check-inline ">
                                                <input
                                                    className="form-check-input"
                                                    onClick={() => setGoogleEnable(!googleEnable)}
                                                    type="checkbox"
                                                    name="status"
                                                    checked={googleEnable}

                                                />
                                                <label
                                                    className="form-check-label fs-14"
                                                    htmlFor="category-Radio1"
                                                >
                                                    Enable Google Analytics
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline ">
                                                <b><a href="https://console.cloud.google.com/apis">Click Here To Create Your Project</a>
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="blue-1 fs-12">
                                            UA-Tracking ID  <span className="red">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="UA-199985055-1"
                                            className="form-control"
                                            value={trackingId}
                                            onChange={(event) => setTrackingId(event.target.value)}
                                        />
                                    </div>


                                    <div className="col-12">
                                        <CustomButton
                                            isBtn
                                            iconName="fa-solid fa-check"
                                            btnName="Save"
                                            ClickEvent={handleGoogleAnalyticSubmit}
                                        />
                                    </div>
                                </form>
                            </DashboardBox>
                        </div>
                        <div className="col-md-6">
                            <h5 className="blue-1 mb-4">Facebook Pixel </h5>
                            <DashboardBox>
                                <form action="" className="form row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between">
                                            <div className="form-check form-check-inline ">
                                                <input
                                                    className="form-check-input"
                                                    onClick={() => setFacebookEnable(!facebookEnable)}
                                                    type="checkbox"
                                                    name="status"
                                                    checked={facebookEnable}

                                                />
                                                <label
                                                    className="form-check-label fs-14"
                                                    htmlFor="category-Radio1"
                                                >
                                                    Enable Facebook Pixel
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline ">
                                                <b><a href="https://developers.facebook.com/docs/facebook-pixel/">Click here to create Facebook pixel</a>
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="blue-1 fs-12">
                                            Facebook Pixel ID <span className="red">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Facebook Pixel ID "
                                            className="form-control"
                                            value={pixelId}
                                            onChange={(event) => setPixelId(event.target.value)}
                                        />
                                    </div>


                                    <div className="col-12">
                                        <CustomButton
                                            isBtn
                                            iconName="fa-solid fa-check"
                                            btnName="Save"
                                            ClickEvent={handleFacebookAnalyticSubmit}
                                        />
                                    </div>
                                </form>
                            </DashboardBox>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Analytics