import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import {
  BLOGGet,
  blogDelete,
  SetblogObj,
} from "../../redux/actions/Blog/Blog.actions";
import { toastError } from "../Utility/ToastUtils";
import { getBlog } from "../../services/blog.service";

function Blog() {
  const blogArray = useSelector((state) => state.blog.blogs);
  const [query, setQuery] = useState("")

  const [blogArr, setBlogArr] = useState([])
  let dispatch = useDispatch();

  const handleBlogGet = async () => {
    try {

      let { data: res } = await getBlog()
      if (res.data) {
        setBlogArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }

    dispatch(BLOGGet());
  };
  // console.log(handleBlogGet(), "erwerwe");
  useEffect(() => {
    handleBlogGet();
  }, []);

  // useState(() => {
  //   console.log(blogArray, "blogArrayblogArrayblogArray")
  //   if (blogArray) {
  //     // console.log()
  //     setBlogArr([...blogArray])

  //   }
  // }, [blogArray])

  const handleBlogDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?")
    if (confirm) {
      dispatch(blogDelete(row));
    }

  };
  const handleBlogEdit = (row) => {
    dispatch(SetblogObj(row));
  };

  const blog_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      width: "20%",
    },
    // {
    //   name: "Author",
    //   selector: (row) => row.author,
    //   width: "20%",
    // },
    {
      name: "Slug",
      grow: 0,
      selector: (row) => row?.slug,
      width: "25%",
    },
    // {
    //   name: "image",
    //   grow: 0,
    //   selector: (row) => row.image,
    //   width: "20%",
    // },
    {
      name: "Author",
      grow: 0,
      selector: (row) => row?.author,
      width: "15%",

    },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcon
          remove
          edit
          // detail
          detailpath="/Blog/View-Post"
          Uniquekey={row.id}
          deletePath="/Blog/post"
          onDeleteClick={() => {
            handleBlogDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            handleBlogEdit(row);
          }}
          editPath="/Blog/post/create"
        />
      ),
      width: "5%",
    },
  ];




  const handleFilterByQuery = (e, requiredParametersArr) => {
    let tempArr = blogArr.filter(el => {
      if (`${el.slug}`.toLowerCase().includes(`${e}`.toLowerCase())
        || `${el.title}`.toLowerCase().includes(`${e}`.toLowerCase())
        || `${el.tags}`.toLowerCase().includes(`${e}`.toLowerCase())
        || `${el.author}`.toLowerCase().includes(`${e}`.toLowerCase())
      ) {
        return true
      } else {
        return false
      }

    })
    setQuery(e)
    setBlogArr([...tempArr])
    // console.log([...tempArr], "...tempArr")
  }


  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Blog List</h5>
                <div className="d-flex gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW BLOG"
                    path="/Blog/post/create"
                  />
                  <SearchBox setQuery={(e) => { handleFilterByQuery(e, ["name"]); }} query={query} extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  // data={blog_data}
                  data={blogArr && blogArr.length > 0 ? blogArr : []}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Blog;
