import {
  // getNewsLetters,
  update,

  // deleteById,
  // get,
  // update,
} from "../../../services/newsletter.service";

export const NEWSLETTER_ADD = "NEWSLETTER_ADD";
export const NEWSLETTER_ADD_SUCCESS = "NEWSLETTER_ADD_SUCCESS";
export const NEWSLETTER_ADD_FAIL = "NEWSLETTER_ADD_FAIL";

export const GET_ALL_NEWSLETTER = "GET_ALL_NEWSLETTERS";
export const GET_ALL_NEWSLETTER_SUCCESS = "GET_ALL_NEWSLETTERS_SUCCESS";
export const GET_ALL_NEWSLETTER_FAIL = "GET_ALL_NEWSLETTERS_FAIL";

export const UPDATE_NEWSLETTER_BY_ID = "UPDATE_NEWSLETTER_BY_ID";
export const UPDATE_NEWSLETTER_BY_ID_SUCCESS =
  "UPDATE_NEWSLETTER_BY_ID_SUCCESS";
export const UPDATE_NEWSLETTER_BY_ID_FAIL = "UPDATE_NEWSLETTER_BY_ID_FAIL";

export const SET_NEWSLETTER_OBJ = "SET_NEWSLETTER_OBJ";
export const SET_NEWSLETTER_OBJ_SUCCESS = "SET_NEWSLETTER_OBJ_SUCCESS";
export const SET_NEWSLETTER_OBJ_FAIL = "SET_NEWSLETTER_OBJ_FAIL";

export const GET_NEWSLETTER_BY_ID = "GET_NEWSLETTER_BY_ID";
export const GET_NEWSLETTER_BY_ID_SUCCESS = "GET_NEWSLETTER_BY_ID_SUCCESS";
export const GET_NEWSLETTER_BY_ID_FAIL = "GET_NEWSLETTER_BY_ID_FAIL";

export const DELETE_NEWSLETTER_BY_ID = "DELETE_NEWSLETTER_BY_ID";
export const DELETE_NEWSLETTER_BY_ID_SUCCESS =
  "DELETE_NEWSLETTER_BY_ID_SUCCESS";
export const DELETE_NEWSLETTER_BY_ID_FAIL = "DELETE_NEWSLETTER_BY_ID_FAIL";

export const newsletterAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: NEWSLETTER_ADD });
    // console.log(formData, "form raction ");
    // let { data: response } = await add(formData);
    // if (response) {
    //   console.log(response, "response raction ");
    //   dispatch({
    //     type: NEWSLETTER_ADD_SUCCESS,
    //     payload: response.message,
    //   });
    //   dispatch(newsletterGet());
    // }
  } catch (err) {
    console.error(err);
    dispatch({ type: NEWSLETTER_ADD_FAIL, payload: err });
  }
};

export const newsletterGet = (formData) => async (dispatch) => {
  try {
    // dispatch({ type: GET_ALL_NEWSLETTER });
    // let { data: response } = await getNewsLetters(formData);
    // if (response) {
    //   console.log(response, "action get  ");
    //   dispatch({
    //     type: GET_ALL_NEWSLETTER_SUCCESS,
    //     payload: { data: response.data, message: response.message },
    //   });
    // }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_NEWSLETTER_FAIL, payload: err });
  }
};

export const SetnewsletterObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_NEWSLETTER_OBJ });
    if (formData) {
      console.log(formData, "formdAtaSetobj action");
      dispatch({
        type: SET_NEWSLETTER_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_NEWSLETTER_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_NEWSLETTER_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const newsletterUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_NEWSLETTER_BY_ID });
    console.log(formData, "formData", id, "id");
    let { data: response } = await update(formData, id);
    if (response) {
      console.log(response, "res");
      dispatch({
        type: UPDATE_NEWSLETTER_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(newsletterGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_NEWSLETTER_BY_ID_FAIL, payload: err });
  }
};

export const newsletterDelete = (id) => async (dispatch) => {
  try {
    // dispatch({ type: DELETE_NEWSLETTER_BY_ID });
    // let { data: response } = await deleteById(id);
    // if (response) {
    //   console.log(response, "response daelete action ");
    //   dispatch({
    //     type: DELETE_NEWSLETTER_BY_ID_SUCCESS,
    //     payload: response.message,
    //   });
    //   dispatch(newsletterGet());
    // }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_NEWSLETTER_BY_ID_FAIL, payload: err });
  }
};
