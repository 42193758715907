import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // ES6
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { AddModal } from "../../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import { getAllNestedCategories } from "../../../redux/actions/Category/Category.actions";
import { BrandGet } from "../../../redux/actions/Brand/brand.actions";
import { PRODUCTAdd } from "../../../redux/actions/Product/Product.actions";
import { ATTRIBUTEGet } from "../../../redux/actions/Attribute/Attribute.actions";
import QuillEditor from "../../../utils/QuillEditor";
import { generateFilePath } from "../../Utility/utils";
import { toastError } from "../../Utility/ToastUtils";
import { getColors } from "../../../services/Color.service";
import { getMaterials } from "../../../services/Material.service";
import { getSizes } from "../../../services/Size.service";
import { getproductQuantityRangess } from "../../../services/ProductQuantityRanges.service";
function GeneralProduct() {
  const dispatch = useDispatch();
  const [internalCode, setInternalCode] = useState("");

  ///////new Fields
  const [material, setMaterial] = useState("");
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [minCartAmount, setMinCartAmount] = useState(0);
  const [weight, setWeight] = useState("");
  //////product details
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [despcription, setDespcription] = useState("");
  const [Specifications, setSpecifications] = useState("");
  const [hsn, setHsn] = useState("");
  const [ships_in_days, setShips_in_days] = useState(0);
  const [dom, setDom] = useState("");
  const [imageArr, setImageArr] = useState([{ image: "", imageAlt: "" }]);


  ///////size of packaging
  const [packaging_Length, setPackaging_Length] = useState(0);
  const [packaging_Width, setPackaging_Width] = useState(0);
  const [packaging_Height, setPackaging_Height] = useState(0);

  ///////dead weight
  const [dead_weight, setDead_weight] = useState(0);

  ///////tags
  const [tags, setTags] = useState("");


  //////category
  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [mainAttributesArr, setMainAttributesArr] = useState([]);

  ///////////////////////////////////////////////////////////////
  const [productImageStr, setProductImageStr] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [status, setStatus] = useState("Publish");
  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const [productDisplay, setproductDisplay] = useState("")

  ///////////////////////////////////////////////////////////////
  const authUser = useSelector((state) => state.auth.user);
  const categoryArr = useSelector((state) => state.category.categories);



  const [colorArr, setColorArr] = useState([]);
  const [materialArr, setMaterialArr] = useState([]);
  const [sizeArr, setSizeArr] = useState([]);
  const [productQuantityRangeArr, setProductQuantityRangeArr] = useState([]);
  const [selectedProductQuantityRangesArr, setSelectedProductQuantityRangesArr] = useState([]);

  const handleGetColors = async () => {
    try {
      let { data: res } = await getColors()
      if (res.data) {
        setColorArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const handleGetMaterial = async () => {
    try {
      let { data: res } = await getMaterials()
      if (res.data) {
        setMaterialArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const handleGetSize = async () => {
    try {
      let { data: res } = await getSizes()
      if (res.data) {
        setSizeArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const handleGetproductQuantityRanges = async () => {
    try {
      let { data: res } = await getproductQuantityRangess()
      if (res.data) {
        setProductQuantityRangeArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  const handleFilterChecked = (arr) => {
    if (arr.length > 0 && arr) {
      return arr.map(el => {
        if (el.subCategoryArr && el.subCategoryArr.length > 0 && el.checked) {
          let tempArr = selectedCategoryArr;
          if (tempArr.some(el => el != el._id)) {
            tempArr.push(el._id)
          }
          setSelectedCategoryArr([...tempArr])
          return { ...el, categoryId: el._id, subCategoryArr: handleFilterChecked(el.subCategoryArr) };
        }
        else {
          if (el.checked) {
            let tempArr = selectedCategoryArr;
            if (tempArr.some(el => el != el._id)) {
              tempArr.push(el._id)
            }
            setSelectedCategoryArr([...tempArr])
            return { ...el, categoryId: el._id }
          }
        }
      })
    }
    else {
      return arr
    }
  }

  const handleSubmit = () => {

    let cat_arr = returnSelectedCategories(mainCategoryArr);

    let obj = {
      internalCode,
      categoryArr: cat_arr.map(el => { return { categoryId: el._id } }),
      name,
      createdBy: authUser?._id,
      sku: sku,
      description: despcription,
      specification: Specifications,
      hsn,
      ships_in_days,
      dom,
      imageArr,
      packaging_Length,
      packaging_Width,
      packaging_Height,
      productQuantityRangeArr: selectedProductQuantityRangesArr,
      dead_weight,
      tags,
      material,
      color,
      size,
      weight,
      status,
      metaTitle,
      metaDescription,
      metaImage,
      productDisplay
    };
    console.log(obj, "send Obj")
    dispatch(PRODUCTAdd(obj));
  };




  useEffect(() => {
    dispatch(getAllNestedCategories());
    handleGetColors()
    handleGetMaterial()
    handleGetSize()
    handleGetproductQuantityRanges()
  }, []);

  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);




  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter(el => el.checked)
    let subCategories = arr.reduce((acc, el) => [...acc, ...el.subCategoryArr.filter(el => el.checked)], [])
    if (subCategories?.length) {
      return [...new_selected_arr, ...returnSelectedCategories(subCategories)]
    }
    else {
      return [...new_selected_arr]
    }
  }



  const handleFileSet = (value, index) => {
    let tempArr = imageArr;
    tempArr[index].image = value
    setImageArr([...tempArr]);
  };
  const handleproductImageAltEntry = (value, index) => {
    console.log(value, index)
    let tempArr = imageArr;
    tempArr[index].imageAlt = value
    setImageArr([...tempArr]);
  };







  const handleImageObjAdd = () => {
    let tempArr = imageArr
    tempArr.push({ image: "", imageAlt: "" })
    console.log(tempArr, "asdas")
    setImageArr([...tempArr])
  }
  const handleImageObjRemove = () => {
    if (imageArr.length > 1) {

      let tempArr = imageArr.filter((el, index) => index != imageArr.length - 1);
      setImageArr([...tempArr])
    }
  }



  const handleRenderNestedCategory = (arr, id, value) => {
    let tempArr = arr.map(el => {
      if (el._id == id) {
        el.checked = value
        return el
      }
      else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value)
        }
        else {
          return el
        }
      }
    })
    return tempArr;
  }

  const handleNestedCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = categoryArr.map(el => {
      if (el._id == id) {
        el.checked = value
        return el
      }
      else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          el.subAttributesArr = handleRenderNestedCategory(el.subCategoryArr, id, value)
          return el
        }
        else {
          return el
        }
      }
    });
    setMainCategoryArr([...tempCategoryArr])
  }


  const handleRenderCheckboxCategory = (obj) => {
    return (
      <div className="col-12 mb-3" style={{ marginLeft: `${obj.level + 5}px` }}>
        <input className="form-check-input pointer" checked={obj.checked} value={tags} onChange={(event) => handleNestedCategoryCheckBoxEvent(obj._id, event.target.checked)} type="checkbox" />
        <label style={{ paddingLeft: 5 }}>
          {obj.name}
        </label>
        {
          obj.checked && obj.subCategoryArr && obj.subCategoryArr.length > 0 && obj.subCategoryArr.map((el) => {
            return (
              handleRenderCheckboxCategory(el)
            )
          })
        }
      </div>
    )
  }




  const handleSelectProductQuantityRanges = (e) => {
    console.log(e, "eeeeeeeeeeeee")
    //  let tempArr= productQuantityRangeArr.filter((e)=>
    //  {
    // e.map((z)=>z.value!=e.value)  
    // })
    let tempArr = e.filter(el => ({ ...el, value: 1 }))
    console.log(tempArr, "temsp21")
    setSelectedProductQuantityRangesArr(tempArr)
  }
  const handleEnterProductQuantityRangesPriceValue = (priceValue, index) => {
    let tempArr = selectedProductQuantityRangesArr
    tempArr[index].value = priceValue
    setSelectedProductQuantityRangesArr([...tempArr])
  }


  return (
    <form className="form">
      <div className="row gy-4 gy-xxl-0">
        <div className="col-12 col-xxl-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>
              <div className="border-bottom pb-3 mb-4 row">

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Product Name <span className="red">*</span>
                  </label>
                  <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Internal Code <span className="red">*</span>
                  </label>
                  <input value={internalCode} onChange={(event) => setInternalCode(event.target.value)} type="text" className="form-control" />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    HSN CODE<span className="red">*</span>
                  </label>
                  <input value={hsn} onChange={(event) => setHsn(event.target.value)} type="text" className="form-control" />
                </div>



                <div className="col-12 col-md-6 mb-3">
                  <label>
                    SKU<span className="red">*</span>
                  </label>
                  <input value={sku} onChange={(event) => setSku(event.target.value)} type="text" className="form-control" />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Product Display<span className="red">*</span>
                  </label>
                  <select className="form-control" value={productDisplay} onChange={(e) => { setproductDisplay(e.target.value) }}>
                    <option value="">None </option>
                    <option value="arrival">New Arrival</option>
                    <option value="bestseller">BestSeller</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Material<span className="red">*</span>
                  </label>

                  <select className="form-control" onChange={(event) => setMaterial(event.target.value)} value={material}>
                    <option value="">Please select an option</option>
                    {
                      materialArr && materialArr.length > 0 && materialArr.map((el) => {
                        return <option value={el._id}>{el.name}</option>
                      })
                    }
                  </select>
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Color<span className="red">*</span>
                  </label>
                  <select className="form-control" value={color} onChange={(event) => setColor(event.target.value)}>
                    <option value="">Please select an option</option>
                    {
                      colorArr && colorArr.length > 0 && colorArr.map((el) => {
                        return <option value={el._id}>{el.name}</option>
                      })
                    }
                  </select>
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Size <span className="red">*</span>
                  </label>
                  <input type="text" className="form-control" value={size} onChange={(event) => setSize(event.target.value)} />
                </div>
              </div>


              <div className="border-bottom pb-3 mb-4 row">
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">Product Quantity Range</h5>
                </div>


                <Select className="mb-4" onChange={(e) => { handleSelectProductQuantityRanges(e); }} isMulti value={selectedProductQuantityRangesArr} options={productQuantityRangeArr && productQuantityRangeArr.length > 0 && productQuantityRangeArr.map(el => ({ ...el, label: !el.to ? `${el.from}+` : `${el.from} - ${el.to}`, value2: el.value, value: el._id, }))} />

                <hr />
                {
                  selectedProductQuantityRangesArr && selectedProductQuantityRangesArr.length > 0 && selectedProductQuantityRangesArr.map((el, index) => {
                    return (
                      <div key={index} className="col-12 col-md-6 mb-3">
                        <label>
                          Enter price for product quantity range of ({el.label})
                        </label>
                        <input value={el.value} onChange={(event) => handleEnterProductQuantityRangesPriceValue(event.target.value, index)} type="number" className="form-control" />
                      </div>
                    )
                  })
                }

              </div>


              <div className="border-bottom pb-3 mb-4 row">
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">Product Shipping & Dimentions Info</h5>
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Ships In (Days)
                  </label>
                  <input value={ships_in_days} onChange={(event) => setShips_in_days(event.target.value)} type="number" className="form-control" />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Packaging Length
                  </label>
                  <input value={packaging_Length} onChange={(event) => setPackaging_Length(event.target.value)} type="number" className="form-control" />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Packaging Width
                  </label>
                  <input value={packaging_Width} onChange={(event) => setPackaging_Width(event.target.value)} type="number" className="form-control" />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Packaging Height
                  </label>
                  <input value={packaging_Height} onChange={(event) => setPackaging_Height(event.target.value)} type="number" className="form-control" />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Dead weight
                  </label>
                  <input value={dead_weight} onChange={(event) => setDead_weight(event.target.value)} type="text" className="form-control" />
                </div>
              </div>

              <div className="border-bottom pb-3 mb-4 row">
                <h5 className="blue-1 mb-4">Tags (Comma Separated) <span className="red">*</span> </h5>
                <div className="col-12 mb-3">
                  <input value={tags} onChange={(event) => setTags(event.target.value)} type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Category <span className="red">*</span></h5>
              {
                mainCategoryArr && mainCategoryArr.length > 0 && mainCategoryArr.map(el => {
                  return (
                    handleRenderCheckboxCategory(el)
                  )
                })
              }
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Weight <span className="red">*</span></h5>
              <>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Weight<span className="red">*</span>
                  </label>
                  <input type="text" min={0} value={weight} onChange={(event) => setWeight(event.target.value)} className="form-control" />
                </div>
              </>
            </div>


            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Description <span className="red">*</span></h5>
              <div className="col-12 mb-3">
                <QuillEditor handleChange={(e) => setDespcription(e)} />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Specifications <span className="red">*</span></h5>
              <div className="col-12 mb-3">
                <QuillEditor handleChange={(e) => setSpecifications(e)} />
              </div>
            </div>
            <div className="row">
              <h5 className="blue-1 mb-4">SEO info <span className="red">*</span></h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} type="text" className="form-control" />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea onChange={(e) => setMetaDescription(e.target.value)} value={metaDescription} name="META DESCRIPTION" className="form-control" rows="3"></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>
                <br />
                <br />
                <img src={`${metaImage}`.includes("base64") ? metaImage : generateFilePath(metaImage)} style={{ height: 80 }} />
                <br />
                <br />
                <FileUpload onFileChange={(val) => setMetaImage(val)} />
              </div>
              <div className="col-12">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
              </div>
            </div>
          </DashboardBox>
        </div>
        <div className="col-12 col-xxl-4">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Product Image Info <span className="red">*</span></h5>
              <div className="row">
                <div className="col-2 me-5">
                  <CustomButton btntype="button" ClickEvent={handleImageObjAdd} isBtn noIcon btnName="+" />
                </div>
                <div className="col-2">
                  <CustomButton btntype="button" ClickEvent={handleImageObjRemove} isBtn noIcon btnName="-" />
                </div>
              </div>
              <div className="col-12 mb-3">
                <label>
                  Product Image<span className="red">*</span>
                </label>
                {imageArr && imageArr.length > 0 && imageArr.map((el, index) => {
                  return (
                    <div key={index} style={{ marginTop: 20, borderBottom: "grey 1px solid", paddingBottom: 15 }} className="row">
                      <div className="col-12">
                        <img src={`${el.image}`.includes("base64") ? el.image : generateFilePath(el.image)} style={{ height: 80 }} />
                        <br />
                        <br />
                        <FileUpload onFileChange={(val) => handleFileSet(val, index)} />
                      </div>
                      <div className="col-12">
                        <label>
                          Product Image alt<span className="red">*</span>
                        </label>
                        <input onChange={(e) => handleproductImageAltEntry(e.target.value, index)} value={el?.imageAlt} type="text" className="form-control" />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </DashboardBox>
        </div>
      </div >
    </form >
  );
}

export default GeneralProduct;
