import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import {
  BLOGGet,
  blogDelete,
  SetblogObj,
} from "../../redux/actions/Blog/Blog.actions";
import { deleteById, get } from "../../services/customization.service";
import { Modal, Box } from "@mui/material";
import { generateFilePath } from "../Utility/utils";
import { toastSuccess } from "../Utility/ToastUtils";

function ProductCustomization() {
  let dispatch = useDispatch();
  const [blogArr, setBlogArr] = useState([]);
  const [ModalBox, setModalBox] = useState(false);

  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [commment, setComment] = useState("");
  const [selectedElement, setSelectedElement] = useState({});






  const handleBlogGet = async () => {
    try {
      let query = "";
      const { data: res } = await get(query);
      console.log(res?.data, "1231313res?.data");
      setBlogArr(res?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleBlogGet();
  }, []);


  // const handleBlogEdit = (row) => {
  //   dispatch(SetblogObj(row));
  // };

  const blog_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Product Name",
      cell: (row) => <img src={generateFilePath(row?.productObj?.imageArr[0]?.image)} style={{ height: 70 }} alt="" srcset="" />,
    },
    {
      name: "Product Name",
      selector: (row) => row?.productObj?.name,
    },
    {
      name: "Product SKU",
      selector: (row) => row?.productObj?.sku,
    },
    {
      name: "Pin code",
      selector: (row) => row?.pinCode,
    },
    {
      name: "Comment",
      selector: (row) => row?.comment,
    },
    {
      name: "view",
      selector: (row) => (
        <CustomButton
          isBtn
          iconName="fa-solid fa-check"
          btnName="View"
          noIcon
          ClickEvent={() => {
            setSelectedElement(row);
            setModalBox(true);
          }}
        />
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <CustomButton
          isBtn
          iconName="fa-solid fa-check"
          btnName="Delete"
          noIcon
          ClickEvent={() => {
            handleBlogDelete(row)
            // setModalBox(true);
          }}
        />
      ),
    },
    // {
    //   name: "view",
    //   selector: (row) => (
    //     <button
    //       onClick={() => {
    //         handleBlogEdit(row);
    //         setModalBox(true);
    //       }}
    //     >
    //       view
    //     </button>
    //   ),
    // },
  ];



  const handleBlogDelete = async (row) => {
    try {
      if (window.confirm("Are You sure you want to delete this data ?") == true) {
        const { data: res } = await deleteById(row._id);
        if (res.message) {
          toastSuccess(res.message);
          handleBlogGet();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const blog_data = [
    {
      id: "1",
      Seq: "1",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "2",
      Seq: "2",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "3",
      Seq: "3",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "4",
      Seq: "4",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "5",
      Seq: "5",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "6",
      Seq: "6",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "7",
      Seq: "7",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "8",
      Seq: "8",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "9",
      Seq: "9",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "10",
      Seq: "10",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
  ];

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Product Customization List</h5>
                {/* <button onClick={()=>setModalBox(true)}>view</button> */}
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  // data={blog_data}
                  data={blogArr && blogArr.length > 0 ? blogArr : []}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>

      {/* <Modal open={ModalBox} 
    onClose={() => setModalBox(false)} 
    aria-labelledby="modal-modal-title" 
    aria-describedby="modal-modal-description">
      <Box className="modal-box">
        <div className="modal-container" style={{ maxWidth: width ? width : "1000px" }}>
          <div className="modal-header">
            <h5>{name}</h5>
            <CustomButton
              isBtn
              btntype="button"
              iconName="ion-close-circled text-white"
              changeClass="border-0 bg-transparent rounded-circle modal-close"
              ClickEvent={(e) => {
                e.preventDefault();
                setModalBox(false);
              }}
            />
          </div>
          <div className="modal-body">
            {ModalType === "product-detail" && <ProductDetail customerData={data} />}
            {ModalType === "customer-detail" && <UserDetailModal customerData={data} />}
            </div>
        </div>
      </Box>
    </Modal> */}

      <Modal
        open={ModalBox}
        onClose={() => setModalBox(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">

          <div className="modal-container" style={{ maxWidth: "80vw", minWidth: "50vw" }}>
            <div className="modal-header">
              <h5 style={{ color: "white", padding: "10px 15px", width: "80%" }}>Product Customization</h5>

              <CustomButton
                isBtn
                btntype="button"
                iconName="ion-close-circled text-white"
                changeClass="border-0 bg-transparent rounded-circle modal-close"
                ClickEvent={(e) => {
                  e.preventDefault();
                  setModalBox(false);
                }}
              />
            </div>
            <div className="modal-body m-0 p-0">

              <table class="table table-striped m-0 p-0">
                <tbody>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Name</td>
                    <td>{selectedElement?.name}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Email</td>
                    <td>{selectedElement?.email}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Product SKU</td>
                    <td>{selectedElement?.productObj?.sku}</td>
                  </tr><tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Product Name</td>
                    <td>{selectedElement?.productObj?.name}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Size</td>
                    <td>{selectedElement?.size}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Quantity</td>
                    <td>{selectedElement?.quantity}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>PinCode</td>
                    <td>{selectedElement?.pinCode} </td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Comment</td>
                    <td>{selectedElement?.comment}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Image</td>
                    <td>
                      {
                        selectedElement?.imageArr && selectedElement?.imageArr.length > 0 && selectedElement?.imageArr.map((el, index) => {
                          return (
                            <div className="my-2">
                              <a href={`${generateFilePath(el.image)}`} target="_blank"><img src={generateFilePath(el.image)} style={{ height: 70 }} alt="" /></a>
                            </div>
                          )
                        })
                      }
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Colors</td>
                    <td>
                      {selectedElement?.color && selectedElement?.color.length > 0 && selectedElement?.color.map(el => {
                        return (
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>{el?.value} <div style={{ marginLeft: 10, height: 20, width: 20, borderRadius: 20, backgroundColor: el?.value }}></div></div>
                        )
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </Box>
      </Modal>
    </main>
  );
}

export default ProductCustomization;
