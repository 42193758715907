import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import {
  blogDelete,
  SetblogObj
} from "../../redux/actions/Blog/Blog.actions";
import { getNewsLetters, deleteNewsLetterById } from "../../services/newsletter.service";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastSuccess } from "../Utility/ToastUtils";

function Subscriber() {

  let dispatch = useDispatch();
  const [blogArr, setBlogArr] = useState([]);

  const handleBlogGet = async () => {

    try {
      let query = ''
      const { data: res } = await getNewsLetters();
      setBlogArr(res?.data);
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(handleBlogGet(), "erwerwe");
  useEffect(() => {
    handleBlogGet();
  }, []);

  const handleBlogDelete = async (id) => {
    try {

      const { data: res } = await deleteNewsLetterById(id);
      if (res.message) {
        toastSuccess(res.message);
        handleBlogGet()
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleBlogEdit = (row) => {
    dispatch(SetblogObj(row));
  };

  const blog_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "20%",
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toDateString(),
      width: "20%",
    },
    {
      name: "Action",
      width: "200px",
      cell: (row) => <ActionIcon remove deletePath={"/Subscriber"} onDeleteClick={() => handleBlogDelete(row._id)} isRedirected Uniquekey={row.id} />,
    },
  ];

  const blog_data = [
    {
      id: "1",
      Seq: "1",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "2",
      Seq: "2",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "3",
      Seq: "3",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "4",
      Seq: "4",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "5",
      Seq: "5",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "6",
      Seq: "6",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "7",
      Seq: "7",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "8",
      Seq: "8",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "9",
      Seq: "9",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "10",
      Seq: "10",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
  ];

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Subscriber List</h5>

              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  // data={blog_data}
                  data={blogArr && blogArr.length > 0 ? blogArr : []}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Subscriber;
