import React, { useEffect, useState } from 'react'
import CustomButton from '../../Utility/Button'
import { DashboardBox } from '../../Utility/DashboardBox'
import FileUpload from "../../Utility/FileUpload";
import { useSelector, useDispatch } from "react-redux";
import { PRODUCTGet } from '../../../redux/actions/Product/Product.actions';
import Select from 'react-select';
const ProductStock = () => {

  const productArr = useSelector((state) => state.product.products);
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState()
  const [sellingPrice, setSellingPrice] = useState("")
  const [mrp, setMrp] = useState("")
  const [stock, setStock] = useState("")
  const handleGetProducts = () => {
    dispatch(PRODUCTGet());
  }
  useEffect(() => {
    handleGetProducts()
  }, []);

  useEffect(() => {
    if (productArr && productArr.length > 0) {
      setDisplayProductArr([...productArr.map(el => {
        el.checked = false
        el.value = el._id
        el.label = el.name
        return el
      })])
    }
  }, [productArr])

  const handleSelectProducts = (data) => {
    setSelectedProducts(data);
  }

  useEffect(() => {
    console.log(selectedProducts, "selectedProducts")
  }, [selectedProducts]);

  const [name, setName] = useState("")
  const [displayProductArr, setDisplayProductArr] = useState([]);
  const [title, setTitle] = useState("")
  const [subTitle, setSubTitle] = useState("")
  const [image, setImage] = useState("")
  const [status, setStatus] = useState(true)
  const handleSubmit = (e) => {
    e.preventDefault();


    // if(`${name}` == ''){
    //   toastError('name is mandatory');
    //   return
    // }



    // let obj = {
    //   name,

    // };
    // if (mailTemplateId) {
    //   console.log(obj, "drt");
    //   dispatch(MailTemplateUpdate(mailTemplateId, obj));
    // } else {
    //   dispatch(MailTemplateAdd(obj));
    // }
    // dispatch(SetMailTemplateObj(null));
  };


  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">

            <div className="col-12 col-md-12">

              <DashboardBox>
                <form action="" className="form row">
                  <div className="col-md-6 mb-3">
                    <h5 className="blue-1 mb-4">Products Information </h5>
                    <label>
                      Products List<span className="red">*</span>
                    </label>
                    <Select
                      isMulti
                      name="colors"
                      options={displayProductArr}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSelectProducts}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <h5 className="blue-1 mb-4"> Address Info</h5>

                    <div className='row'>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          Name <span className="red">*</span>
                        </label>
                        <input
                          name="first_name"
                          className="form-control"
                          type="text"
                          required=""
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>Email</label>
                        <input
                          name="last_name"
                          className="form-control"
                          type="text"
                          required=""
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          Phone Number
                          <span className="red">*</span>
                        </label>
                        <input name="text" className="form-control" type="email" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          Address
                          <span className="red">*</span>
                        </label>
                        <input name="text" className="form-control" type="email" />
                      </div>

                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          City
                          <span className="red">*</span>
                        </label>
                        <input name="text" className="form-control" type="email" />
                      </div>

                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          State
                          <span className="red">*</span>
                        </label>
                        <input name="text" className="form-control" type="email" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          Country
                          <span className="red">*</span>
                        </label>
                        <input name="text" className="form-control" type="email" />
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <label>
                          Pincode
                          <span className="red">*</span>
                        </label>
                        <input name="text" className="form-control" type="email" />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-8'>
                      <h5 className="blue-1 mb-4">Order Package  <span className="red">*</span></h5>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Product Name</th>
                            <th scope="col">Sku</th>
                            <th scope="col">Buying Price</th>
                            <th scope="col">Selling Price</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price</th>
                            <th scope="col">Handle</th>
                          </tr>
                        </thead>
                        <tbody>



                          {
                            selectedProducts && selectedProducts.map((product) => {
                              return (

                                <tr>
                                  <td >{product?.name}</td>
                                  <td>{product.sku}</td>
                                  <td><input type="text" className='form-control' /></td>
                                  <td><input type="text" className='form-control' /></td>
                                  <td><input type="text" className='form-control' /></td>
                                  <td><input type="text" className='form-control' /></td>
                                  <td><button className='btn btn-secondary'> <i className='fa fa-times'></i></button></td>
                                </tr>


                                // <div className="col-12">
                                //   <div className="border-bottom pb-3 mb-4 row">
                                //     <div className="col-12 col-md-6 mb-3">
                                //       <label>
                                //         From Name<span className="red">*</span>
                                //       </label>
                                //       <input type="number" min={0} value={sellingPrice} onChange={(event) => setSellingPrice(event.target.value)} className="form-control" />
                                //     </div>

                                //     <div className="col-12 col-md-6 mb-3">
                                //       <label>
                                //         From Address<span className="red">*</span>
                                //       </label>
                                //       <input value={mrp} onChange={(event) => setMrp(event.target.value)} type="number" className="form-control" />
                                //     </div>

                                //     <div className="col-12 col-md-4 mb-3">
                                //       <label>
                                //         SELLING PRICE<span className="red">*</span>
                                //       </label>
                                //       <input type="number" min={0} value={sellingPrice} onChange={(event) => setSellingPrice(event.target.value)} className="form-control" />
                                //     </div>

                                //     <div className="col-12 col-md-4 mb-3">
                                //       <label>
                                //         MRP<span className="red">*</span>
                                //       </label>
                                //       <input value={mrp} onChange={(event) => setMrp(event.target.value)} type="number" className="form-control" />
                                //     </div>
                                //     <div className="col-12 col-md-4 mb-3">
                                //       <label>
                                //         STOCK<span className="red">*</span>
                                //       </label>
                                //       <input value={stock} onChange={(event) => setStock(event.target.value)} type="number" className="form-control" />
                                //     </div>


                                //   </div>
                                // </div>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className='col-md-4'>
                      <h5 className="blue-1 mb-4">Summary  <span className="red">*</span></h5>

                      <p>Total Quantiity:sdfs</p>
                      <p>Total Quantiity:sdfs</p>
                      <p>Total Quantiity:sdfs</p>
                      <p>Total Quantiity:sdfs</p>
                    </div>
                  </div>


                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="ADD"
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>

          </div>
        </div>
      </section>
    </main >
  )
}

export default ProductStock