import {
  addLogo,
  deleteLogo,
  getLogo,
  updateLogoById,
} from "../../../services/logo.service";

export const LOGO_ADD = "LOGO_ADD";
export const LOGO_ADD_SUCCESS = "LOGO_ADD_SUCCESS";
export const LOGO_ADD_FAIL = "LOGO_ADD_FAIL";

export const GET_ALL_LOGOS = "GET_ALL_LOGOS";
export const GET_ALL_LOGOS_SUCCESS = "GET_ALL_LOGOS_SUCCESS";
export const GET_ALL_LOGOS_FAIL = "GET_ALL_LOGOS_FAIL";

export const UPDATE_LOGO_BY_ID = "UPDATE_LOGO_BY_ID";
export const UPDATE_LOGO_BY_ID_SUCCESS = "UPDATE_LOGO_BY_ID_SUCCESS";
export const UPDATE_LOGO_BY_ID_FAIL = "UPDATE_LOGO_BY_ID_FAIL";

export const SET_LOGO_OBJ = "SET_LOGO_OBJ";
export const SET_LOGO_OBJ_SUCCESS = "SET_LOGO_OBJ_SUCCESS";
export const SET_LOGO_OBJ_FAIL = "SET_LOGO_OBJ_FAIL";

export const GET_LOGO_BY_ID = "GET_LOGO_BY_ID";
export const GET_LOGO_BY_ID_SUCCESS = "GET_LOGO_BY_ID_SUCCESS";
export const GET_LOGO_BY_ID_FAIL = "GET_LOGO_BY_ID_FAIL";

export const DELETE_LOGO_BY_ID = "DELETE_LOGO_BY_ID";
export const DELETE_LOGO_BY_ID_SUCCESS = "DELETE_LOGO_BY_ID_SUCCESS";
export const DELETE_LOGO_BY_ID_FAIL = "DELETE_LOGO_BY_ID_FAIL";

export const LOGOAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOGO_ADD });
    let { data: response } = await addLogo(formData);
    if (response) {
      console.log(response);
      dispatch({
        type: LOGO_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(LOGOGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: LOGO_ADD_FAIL, payload: err });
  }
};

export const LOGOGet = (formData) => async (dispatch) => {
  try {
    console.log(formData, "formDAtaas");
    dispatch({ type: GET_ALL_LOGOS });
    let { data: response } = await getLogo(formData);
    console.log(response, "get logo3");
    if (response) {
      dispatch({
        type: GET_ALL_LOGOS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: LOGO_ADD_FAIL, payload: err });
  }
};

export const SetLOGOObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOGO_OBJ });
    if (formData) {
      dispatch({
        type: SET_LOGO_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_LOGO_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_LOGO_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const LOGOUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_LOGO_BY_ID });
    let { data: response } = await updateLogoById(formData, id);
    if (response) {
      console.log(response);
      dispatch({
        type: UPDATE_LOGO_BY_ID_SUCCESS,
        payload: response,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_LOGO_BY_ID_FAIL, payload: err });
  }
};

export const LOGODelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_LOGO_BY_ID });
    let { data: response } = await deleteLogo(id);
    if (response) {
      console.log(response, "response23");
      dispatch({
        type: DELETE_LOGO_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(LOGOGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_LOGO_BY_ID_FAIL, payload: err });
  }
};
