import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import tabClick from "../Utility/TabClick";
import ActionIcon from "../Utility/ActionIcon";
import { useSelector, useDispatch } from "react-redux";
import {
  PaymentGatewayAdd,
  PaymentGatewayGet,
  PaymentGatewayUpdate,
  SetPaymentGatewayObj,
  PaymentGatewayDelete,
} from "../../redux/actions/PaymentGateway/PaymentGateway.action";
// import { display } from "@mui/system";
function PaymentGateway() {
  // ====================================================================================
  const dispatch = useDispatch();
  //url token secret key
  const [liveReady, setLiveReady] = useState(false);

  const [clientId, setClientId] = useState("");
  const [key, setKey] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [secretKey, setSecetKey] = useState("");
  const [paymentUrl, setPaymentUrl] = useState("");
  const [image, setImage] = useState("");
  const [paymentType, setPaymentType] = useState("");

  const [paymentGatewayId, setPaymentGatewayId] = useState("");

  console.log(liveReady, "liveReady");
  const PaymentGatewayArr = useSelector(
    (state) => state.paymentGateway.PaymentGateways
  );
  // console.log(PaymentGatewayArr, "PaymentGatewayArr");
  const PaymentGatewayObj = useSelector(
    (state) => state.paymentGateway.PaymentGatewayObj
  );
  console.log(PaymentGatewayObj, "PaymentGatewayObj");
  useEffect(() => {
    if (PaymentGatewayObj?.length) {
      setPaymentType(PaymentGatewayObj.paymentType);
      setClientId(PaymentGatewayObj.clientId);
      setKey(PaymentGatewayObj.key);
      setName(PaymentGatewayObj.name);
      setSecetKey(PaymentGatewayObj.secretKey);
      setPaymentUrl(PaymentGatewayObj.paymentUrl);
      setImage(PaymentGatewayObj.image);
      setPaymentGatewayId(PaymentGatewayObj._id);
    }
  }, [PaymentGatewayObj]);

  useEffect(() => {
    dispatch(PaymentGatewayGet());
  }, []);
  const payment_gateway_columns = [
    {
      name: "ID",
      selector: (row) => row.sl,
      sortable: true,
      width: "33.33%",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      width: "33.33%",
    },
    {
      name: "ACTIVATE",
      cell: (row) => (
        <Switch
          defaultChecked={row.check}
          onChange={(e) => handleChange(e.target.checked, row)}
        />
      ),
      width: "33.33%",
    },
  ];
  const handleDelete = (id) => {
    dispatch(PaymentGatewayDelete(id));
  };
  const handleEdit = (row) => {
    dispatch(SetPaymentGatewayObj(row));
  };
  const payment_gateway_columnsArray = [
    {
      name: "S.NO.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "PAYMENT-NAME",
      selector: (row) => row.paymentType,
    },
    {
      name: "secret-key",
      selector: (row) => row.secretKey,
    },
    {
      name: "clientId",
      selector: (row) => row.clientId,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => (
        <ActionIcon
          remove
          edit
          Uniquekey={row.id}
          deletePath="/Payment-Gateway"
          onDeleteClick={() => {
            // console.log(row, "delete");
            handleDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            console.log(row, "edit data");
            handleEdit(row);
          }}
          editPath="/Payment-Gateway"
        />
      ),
    },
  ];
  const handleFileSet = (value) => {
    setImage(value);
  };
  const payment_gateway_data = [
    {
      id: "1",
      sl: "1",
      name: "CASH ON DELIVERY",
      check: false,
    },
    {
      id: "2",
      sl: "2",
      name: "WALLET",
      check: false,
    },
    {
      id: "3",
      sl: "3",
      name: "PAYPAL",
      check: true,
    },
    {
      id: "4",
      sl: "4",
      name: "STRIPE",
      check: false,
    },
    {
      id: "5",
      sl: "5",
      name: "PAYSTACK",
      check: false,
    },
    {
      id: "6",
      sl: "6",
      name: "RAZORPAY",
      check: false,
    },
  ];

  const [tabList, settabList] = useState([
    {
      tabName: "PAYPAL",
      active: true,
    },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      paymentType,
      key,
      secretKey,
      image,
      liveReady,
    };
    if (paymentType == "PAYPAL") {
      obj.clientId = clientId;
    }
    if (paymentType == "STRIPE") {
      obj.name = name;
    }
    if (paymentType == "PAYSTACK") {
      obj.email = email;
      obj.paymentUrl = paymentUrl;
    }
    console.log(obj, "drt");
    if (PaymentGatewayObj?._id) {
      dispatch(PaymentGatewayUpdate(setPaymentGatewayId, obj));
    } else {
      dispatch(PaymentGatewayAdd(obj));
    }
  };

  const handleChange = (checked, row) => {
    let temp = [];
    let index = tabList.findIndex((item) => item.tabName === row.name);
    // console.log({ checked, row,index  });
    if (checked && index === -1) {
      temp = [
        ...tabList,
        {
          tabName: row.name,
          active: false,
        },
      ];
    } else {
      temp = [...tabList];
      temp.splice(index, 1);
    }
    settabList([...temp]);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-6">
              <h5 className="blue-1 mb-4">Activation</h5>
              <DashboardTable>
                <DataTable
                  columns={payment_gateway_columns}
                  data={payment_gateway_data}
                  pagination
                />
              </DashboardTable>
            </div>

            <div className="col-12 col-md-6">
              <ul
                className="nav nav-pills dashboard-pills mb-4 gap-2"
                id="pills-tab"
                role="tablist"
              >
                {tabList.map((item, i) => {
                  if (
                    item.tabName !== "CASH ON DELIVERY" &&
                    item.tabName !== "WALLET"
                  ) {
                    return (
                      <li key={i}>
                        <CustomButton
                          navPills
                          btnName={item.tabName}
                          pillActive={item.active ? true : false}
                          ClickEvent={() => {
                            tabClick(i, tabList, settabList);
                          }}
                        />
                      </li>
                    );
                  }
                })}
              </ul>
              <DashboardBox>
                {tabList.map((item) => {
                  if (item.active) {
                    return (
                      <>
                        <h5 className="blue-1 mb-4">
                          {item.tabName} Configuration
                        </h5>
                        <form action="#" className="form row">
                          {item.tabName === "PAYPAL" && (
                            <div className="col-12">
                              <div className="d-flex">
                                <div className="form-check form-check-inline d-flex align-items-center">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="category-status"
                                    value="option1"
                                    id="paypal-sandbox"
                                    onChange={() => {
                                      setLiveReady(false);
                                    }}
                                  />
                                  <label
                                    className="form-check-label fs-14 pointer"
                                    htmlFor="paypal-sandbox"
                                  >
                                    Sandbox
                                  </label>
                                </div>
                                <div className="form-check form-check-inline d-flex align-items-center">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="category-status"
                                    value="option2"
                                    id="paypal-live"
                                    onChange={() => {
                                      setLiveReady(true);
                                    }}
                                  />
                                  <label
                                    className="form-check-label fs-14 pointer"
                                    htmlFor="paypal-live"
                                  >
                                    Live
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}

                          {item.tabName === "PAYPAL" && (
                            <div className="col-12">
                              <label className="blue-1 fs-12">
                                {item.tabName} CLIENT ID
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                value={clientId}
                                onChange={(event) => {
                                  setClientId(event.target.value);
                                  setPaymentType("PAYPAL");
                                }}
                              />
                            </div>
                          )}
                          {item.tabName === "PAYSTACK" && (
                            <div className="col-12">
                              <label className="blue-1 fs-12">
                                {item.tabName} MERCHANT EMAIL
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(event) => {
                                  setEmail(event.target.value);
                                  setPaymentType("PAYSTACK");
                                }}
                              />
                            </div>
                          )}

                          {(item.tabName === "STRIPE" ||
                            item.tabName === "PAYSTACK") && (
                            <div className="col-12">
                              <label className="blue-1 fs-12">
                                {item.tabName} KEY
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={key}
                                onChange={(event) => setKey(event.target.value)}
                              />
                            </div>
                          )}

                          {item.tabName === "STRIPE" && (
                            <div className="col-12">
                              <label className="blue-1 fs-12">
                                {item.tabName} USER NAME
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={name}
                                onChange={(event) => {
                                  setName(event.target.value);
                                  setPaymentType("STRIPE");
                                }}
                              />
                            </div>
                          )}

                          <div className="col-12">
                            <label className="blue-1 fs-12">
                              {item.tabName} CLIENT SECRET KEY
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={secretKey}
                              onChange={(event) =>
                                setSecetKey(event.target.value)
                              }
                            />
                          </div>

                          {item.tabName === "PAYSTACK" && (
                            <div className="col-12">
                              <label className="blue-1 fs-12">
                                {item.tabName} PAYMENT URL
                              </label>
                              <input
                                type="url"
                                className="form-control"
                                value={paymentUrl}
                                onChange={(event) => {
                                  setPaymentUrl(event.target.value);
                                  setPaymentType("PAYSTACK");
                                }}
                              />
                            </div>
                          )}

                          <div className="col-12">
                            <label className="blue-1 fs-12">
                              GATEWAY LOGO (400X166)PX
                            </label>
                            <FileUpload onFileChange={handleFileSet} />
                          </div>

                          <div className="col-12 text-center mt-4">
                            <CustomButton
                              iconName="fa-solid fa-check"
                              btnName="Save"
                              isBtn
                              ClickEvent={handleSubmit}
                            />
                          </div>
                        </form>
                      </>
                    );
                  }
                })}
              </DashboardBox>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-12">
          <h5 className="blue-1 mb-4"> ALL PAYMENT LIST</h5>
          <DashboardTable>
            <DataTable
              columns={payment_gateway_columnsArray}
              // data={payment_gateway_data}
              data={
                PaymentGatewayArr && PaymentGatewayArr.length > 0
                  ? PaymentGatewayArr
                  : []
              }
              pagination
            />
          </DashboardTable>
        </div>
      </section>
    </main>
  );
}

export default PaymentGateway;
