import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import SearchBox from "../../Utility/SearchBox";

import { deleteColor, getColors } from "../../../services/Color.service";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
import AddViewProductQuantityRanges from "./AddProductQuantityRanges";
import {
  deleteproductQuantityRanges,
  getproductQuantityRangess,
} from "../../../services/ProductQuantityRanges.service";
export default function ViewProductQuantityRanges() {
  // ==============================================================================================================
  const [displayTestimonialArr, setDisplayTestimonialArr] = useState([]);
  const [query, setQuery] = useState("");
  const [materialArr, setTestimonialMainArr] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [count, setCount] = useState(0);

  const handleGet = async () => {
    try {
      let { data: res } = await getproductQuantityRangess();
      if (res.message) {
        setDisplayTestimonialArr(res.data);
        setTestimonialMainArr(res.data);
        toastSuccess(res.message);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleDelete = async (row) => {
    try {
      let confirm = window.confirm("Do you really want to delete this item?");
      if (confirm) {
        let { data: res } = await deleteproductQuantityRanges(row._id);

        if (res.message) {
          toastSuccess(res.message);
          handleGet();
        }
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGet();
  }, [count]);

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) =>
        row?.from ? (!row?.to ? `${row?.from}+` : row?.from) : "N.A.",
    },
    {
      name: "To",
      selector: (row) => (row?.to ? row?.to : "N.A."),
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row._id}
          remove
          edit
          deletePath="/Product/View-Product-Quantity-Ranges"
          onDeleteClick={() => handleDelete(row)}
          isRedirected={true}
          onEditClick={() => setSelectedObj(row)}
          editPath="/Product/View-Product-Quantity-Ranges"
        />
      ),
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    console.log(
      displayTestimonialArr,
      "displayTestimonialArrdisplayTestimonialArr"
    );
    console.log(e, "r");
    let tempArr = displayTestimonialArr.filter((el) => {
      for (const ele of requiredParametersArr) {
        if (`${el.from}`.includes(`${e}`) || `${el.to}`.includes(`${e}`)) {
          return true;
        }
        // console.log(`${el[ele]}`.toLowerCase().includes(`${e}`.toLowerCase()), "ele,el")
        // if (`${el[`${ele}`.toLowerCase()]}`.toLowerCase().includes(`${e}`.toLowerCase())) {
        //     // console.log("true")
        //     return true;
        // }
        else {
          return false;
        }
      }
    });
    setQuery(e);
    setTestimonialMainArr([...tempArr]);
    console.log([...tempArr], "...tempArr");
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Product Quantity Ranges</h5>
                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
              </div>
              <DashboardBox>
                <AddViewProductQuantityRanges
                  selectedObj={selectedObj}
                  setCount={setCount}
                />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Product Quantity Range List</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={
                    materialArr && materialArr.length > 0 ? materialArr : []
                  }
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

