import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/userOrder";

export const getAllOrders = (query) => {
  console.log(query, "query234e213")
  return axiosApiInstance.get(`${serverUrl}/getAllOrdersForAdmin?${query}`);
};

export const getOrderById = async (id) => {
  return axiosApiInstance.get(`${serverUrl}/getbyId/${id}`);
};

export const updateOrderById = async (id, obj) => {
  return axios.patch(`${serverUrl}/updateById/${id}`, obj);
};
export const updateStatusOrderById = async (id, obj) => {
  return axios.patch(`${serverUrl}/updateOrderById/${id}`, obj);
};

export const updateStatusProductsInBulk = async (obj) => {
  return axios.patch(`${serverUrl}/updateStatusProductsInBulk`, obj);
};



export const downloadAllOrder = async () => {
  return axiosApiInstance.get(`${serverUrl}/downloadAllOrder?`);
};
