import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import {
  BLOGGet,
  blogDelete,
  SetblogObj,
} from "../../redux/actions/Blog/Blog.actions";
import { deleteById, get } from "../../services/bookAppointment.service";
import { Modal, Box } from "@mui/material";
import { toastSuccess } from "../Utility/ToastUtils";


function BookAppointment() {

  let dispatch = useDispatch();
  const [blogArr, setBlogArr] = useState([]);


  const [ModalBox, setModalBox] = useState(false);

  const [email, setEmail] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [companyName, setcompanyName] = useState("");



  const [selectedElement, setSelectedElement] = useState({});





  const handleBlogGet = async () => {
    try {
      let query = ''
      const { data: res } = await get(query);
      if (res.data) {
        setBlogArr(res?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(handleBlogGet(), "erwerwe");
  useEffect(() => {
    handleBlogGet();
  }, []);

  const handleBlogDelete = async (row) => {
    try {
      if (window.confirm("Are You sure you want to delete this data ?") == true) {
        const { data: res } = await deleteById(row._id);
        if (res.message) {
          toastSuccess(res.message);
          handleBlogGet();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  // const handleBlogEdit = (row) => {
  //   dispatch(SetblogObj(row));
  // };

  const blog_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      // width: "20%",
    },
    {
      name: "Appointment Date",
      selector: (row) => new Date(row?.appointmentDate).toDateString(),
      // width: "20%",
    },
    {
      name: "Appointment time",
      selector: (row) => row.appointmentTime,
      // width: "20%",
    },
    {
      name: "Comment",
      selector: (row) => row?.comment ? `${row?.comment?.slice(0, 50)}...` : "NA",
      // width: "20%",
    },
    {
      name: "Company Name",
      selector: (row) => row.companyName,
      // width: "20%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      // width: "20%",
    },
    {
      name: "view",
      selector: (row) => (
        <CustomButton
          isBtn
          iconName="fa-solid fa-check"
          btnName="View"
          noIcon
          ClickEvent={() => {
            setSelectedElement(row)
            setModalBox(true);
          }}
        />
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <CustomButton
          isBtn
          iconName="fa-solid fa-check"
          btnName="Delete"
          noIcon
          ClickEvent={() => {
            handleBlogDelete(row)
            // setModalBox(true);
          }}
        />
      ),
    },
    // {
    //   name: "Created At",
    //   selector: (row) => new Date(row.createdAt).toDateString(),
    //   width: "20%",
    // },

  ];


  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Appointment List</h5>

              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  // data={blog_data}
                  data={blogArr && blogArr.length > 0 ? blogArr : []}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>


      <Modal
        open={ModalBox}
        onClose={() => setModalBox(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <div className="modal-container" style={{ maxWidth: "80vw", minWidth: "50vw" }}>
            <div className="modal-header">
              <h5 style={{ color: "white", padding: "10px 15px", width: "80%" }}>Appointment Details</h5>

              <CustomButton
                isBtn
                btntype="button"
                iconName="ion-close-circled text-white"
                changeClass="border-0 bg-transparent rounded-circle modal-close"
                ClickEvent={(e) => {
                  e.preventDefault();
                  setModalBox(false);
                }}
              />
            </div>
            <div className="modal-body m-0 p-0">

              <table class="table table-striped m-0 p-0">
                <tbody>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Name</td>
                    <td>{selectedElement?.name}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Email</td>
                    <td>{selectedElement?.email}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Company Name</td>
                    <td>{selectedElement?.companyName} </td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Time Zone</td>
                    <td>{selectedElement?.timeZone} </td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Appointment Date</td>
                    <td>{new Date(selectedElement?.appointmentDate).toDateString()}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Appointment Time</td>
                    <td>{selectedElement?.appointmentTime}</td>
                  </tr>
                  <tr>
                    <td scope="row" style={{ borderRight: "solid 1px #edebeb" }}>Comment</td>
                    <td>{selectedElement?.comment}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Modal>

    </main >
  );
}

export default BookAppointment;
