import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as GALLERY from "../../actions/Gallery/Gallery.actions";

const initialState = {
  gallery: null,
  galleryobj: null,
  loading: false,
  error: null,
};

export const GalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GALLERY.GALLERY_ADD:
      return {
        ...state,
        loading: true,
      };
    case GALLERY.GALLERY_ADD_SUCCESS:
      //   console.log(act);
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case GALLERY.GALLERY_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GALLERY.GET_ALL_GALLERY:
      return {
        ...state,
        loading: true,
      };
    case GALLERY.GET_ALL_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        gallery: action.payload.data,
      };
    case GALLERY.GET_ALL_GALLERY_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case GALLERY.UPDATE_GALLERY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GALLERY.UPDATE_GALLERY_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GALLERY.UPDATE_GALLERY_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
