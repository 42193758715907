import React, { useEffect, useState } from 'react'
import { getSystemSetting, updateSystemSetting } from '../../services/system.service';
import CustomButton from '../Utility/Button'
import { DashboardBox } from '../Utility/DashboardBox'
import FileUpload from "../Utility/FileUpload";
import { toastError, toastSuccess } from '../Utility/ToastUtils';
import { generateFilePath } from '../Utility/utils';

const Setting = () => {

    const [headerMarquee, setHeaderMarquee] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [homedecor1, setHomeDecor1] = useState({
      image:"",
      link:""
    })
    const [homedecor2, setHomeDecor2] = useState({
      image:"",
      link:"dfsdsfd"
    })
    const [homedecor3, setHomeDecor3] = useState({
      image:"",
      link:""
    })
    const [homedecor4, setHomeDecor4] = useState({
      image:"",
      link:""
    })
    const [homedecor5, setHomeDecor5] = useState({
      image:"",
      link:""
    })
    const [image, setImage] = useState("")
    const [status, setStatus] = useState(false)
    const [systemSetting, setSystemSetting] = useState([])
    const [systemSettingId, setSystemSettingId] = useState("")
    const handleGetAllUsers =async () => {
     
        try 
        {
           const { data: res } = await getSystemSetting();
           setSystemSetting(res?.data[0]);
       } catch (error) {
           console.error(error);
       }  
   
         };
   
   
         useEffect(() => {
           handleGetAllUsers();
         }, []);

         useEffect(() => {
           if(systemSetting){
          
            setHeaderMarquee(systemSetting?.headerMarquee);
            setImage(systemSetting?.discountBanner);
            if(systemSetting?.homedecor1){
                setHomeDecor1(systemSetting?.homedecor1);
            } 
            if(systemSetting?.homedecor2){
               setHomeDecor2(systemSetting?.homedecor2);
            }

            if(systemSetting?.homedecor3){
              setHomeDecor3(systemSetting?.homedecor3);
           
            }
            if(systemSetting?.homedecor4){
            setHomeDecor4(systemSetting?.homedecor4);

            }
            if(systemSetting?.homedecor5){
            setHomeDecor5(systemSetting?.homedecor5);

            }
          
            setStatus(systemSetting?.isDiscountActive);
            setSystemSettingId(systemSetting._id)
            
           }
          }, [systemSetting]);
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();

          let obj = {
                headerMarquee,
                    discountBanner:image,
                    isDiscountActive:status,
                    homedecor1,
                    homedecor2,
                    homedecor3,
                    homedecor4,
                    homedecor5,
           }

           console.log(obj,"homedecor2homedecor2homedecor2homedecor2")
        try {
            const { data: res } =     await updateSystemSetting(systemSettingId,obj)
            toastSuccess(res?.message);

            handleGetAllUsers();
        } catch (error) {
            toastError(error)
        }
    };


  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <h5 className="blue-1 mb-4"> Setting</h5>
              <DashboardBox>
                <form action="" className="form row">
                  <div className="col-md-6 mb-3">
                    <label className="blue-1 fs-12">
                       Header Title<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={headerMarquee}
                      onChange={(event) => setHeaderMarquee(event.target.value)}
                    />
                  </div>
                  
                <div className="col-6 mb-3">
                <label> Discount Banner (1300x920)PX </label>
                
                <img src={`${image}`.includes("base64") ? image : generateFilePath(image)} style={{ height: 80 }} />
                <FileUpload onFileChange={(val) => setImage(val)} />
              
              </div>
              <h3 className="">Home Decor</h3>
              <div className="col-6 mb-3">
                <label> Home Decor1 (1300x920)PX </label>
                <img src={`${homedecor1?.image}`.includes("base64") ? homedecor1?.image : generateFilePath(homedecor1?.image)} style={{ height: 80 }} />
                <FileUpload onFileChange={(val) => setHomeDecor1({image:val,link:homedecor1.link})} />
                <input
                      type="text"
                      placeholder="Link"
                      className="form-control mt-2"
                      value={homedecor1?.link}
                      onChange={(event) =>  setHomeDecor1({image:homedecor1?.image,link:event.target.value})}
                    />
              </div>
              <div className="col-6 mb-3">
                <label> Home Decor2 (1300x920)PX </label>
                <img src={`${homedecor2?.image}`.includes("base64") ? homedecor2?.image : generateFilePath(homedecor2?.image)} style={{ height: 80 }} />
                <FileUpload onFileChange={(val) => setHomeDecor2({image:val,link:homedecor2.link})} />
                <input
                      type="text"
                      placeholder="Link"
                      className="form-control mt-2"
                      value={homedecor2?.link}
                      onChange={(event) => setHomeDecor2({image:homedecor2.image,link:event.target.value})}
                    />
              </div>

              <div className="col-6 mb-3">
                <label> Home Decor3 (1300x920)PX </label>
                <img src={`${homedecor3?.image}`.includes("base64") ? homedecor3?.image : generateFilePath(homedecor3?.image)} style={{ height: 80 }} />
                <FileUpload onFileChange={(val) => setHomeDecor3({image:val,link:homedecor3.link})} />
                <input
                      type="text"
                      placeholder="Link"
                      className="form-control mt-2"
                      value={homedecor3?.link}
                      onChange={(event) => setHomeDecor3({image:homedecor3.image,link:event.target.value})}
                    />
              </div>

              <div className="col-6 mb-3">
                <label> Home Decor4 (1300x920)PX </label>
                <img src={`${homedecor4?.image}`.includes("base64") ? homedecor4?.image : generateFilePath(homedecor4?.image)} style={{ height: 80 }} />
                <FileUpload onFileChange={(val) => setHomeDecor4({image:val,link:homedecor4.link})} />
                <input
                      type="text"
                      placeholder="Link"
                      className="form-control mt-2"
                      value={homedecor4?.link}
                      onChange={(event) =>  setHomeDecor4({image:homedecor4.image,link:event.target.value})}
                    />
              </div>
              <div className="col-6 mb-3">
                <label> Home Decor5(1300x920)PX </label>
                <img src={`${homedecor5?.image}`.includes("base64") ? homedecor5?.image : generateFilePath(homedecor5?.image)} style={{ height: 80 }} />
                <FileUpload onFileChange={(val) => setHomeDecor5({image:val,link:homedecor5.link})} />
                <input
                      type="text"
                      placeholder="Link"
                      className="form-control mt-2"
                      value={homedecor5?.link}
                      onChange={(event) => setHomeDecor5({image:homedecor5?.image,link:event.target.value})}
                    />
              </div>
              
              <div className="col-6">
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                onClick={() => setStatus(true)}
                type="radio"
                name="status"
                checked={status}

              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="status"

                checked={!status}
                onClick={() => setStatus(false)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div>
                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="ADD"
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
           
          </div>
        </div>
      </section>
    </main>
  )
}

export default Setting