import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  getAllOrders,
  updateStatusProductsInBulk,
} from "../../../services/order.service";
import { deleteProductsInBulk } from "../../../services/product.service";
import { orderStatusObj } from "../../../utils/orderStatusObj";
import CustomButton from "../../Utility/Button";
import { ORDER_STATUS } from "../../Utility/constants";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
function PendingOrder({ name }) {
  const [orders, setOrders] = useState([]);
  const [displayOrders, setdisplayOrders] = useState([]);
  const [displayButtons, setDisplayButtons] = useState(false);
  const [status, setStatus] = useState("");
  const [query, setQuery] = useState("");

  const getOrder = async () => {
    try {
      const { data: res } = await getAllOrders();
      console.log(res, "res32423441234");
      if (res) {
        // setOrders(res.data.filter((el) => el.status != "CANCELLED"));
        setOrders([
          ...res.data.map((el) => {
            el.checked = false;
            return el;
          }),
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckOrder = (row, index, e) => {
    let tempArr = orders.map((el) => {
      if (row._id == el._id) {
        el.checked = !el.checked;
      }
      return el;
    });
    handleGetOrderCheckedStatus(tempArr);
    setOrders([...tempArr]);
    console.log(index, row, e.target.checked);
  };

  const handleGetOrderCheckedStatus = (tempArr) => {
    if (tempArr.some((el) => el.checked == true)) {
      setDisplayButtons(true);
    } else {
      setDisplayButtons(false);
    }
  };

  const handleClearSelection = () => {
    let tempArr = orders.map((el) => {
      el.checked = false;
      return el;
    });

    handleGetOrderCheckedStatus(tempArr);
    setOrders(tempArr);
  };

  const handleDeleteSelected = async () => {
    try {
      let selectedArr = orders
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            orderId: el._id,
          };
          return obj;
        });
      if (`${status}` == "") {
        toastError("Please select order status");
        return;
      }
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one order to perform this action");
        return;
      }

      let obj = {
        orderId: selectedArr,
        status,
      };
      let { data: res } = await updateStatusProductsInBulk(obj);
      if (res.message) {
        toastSuccess(res.message);
        getOrder();
      }
    } catch (e) {
      toastError(e);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const PendingOrder_columns = [
    // {
    //   name: "SL",
    //   cell: (row, index) => <>
    //     <span onClick={(e) => handleCheckOrder(row, index, e)}>
    //       {row.checked == true ? <i className="fa fa-check-square" /> : <i className="fa fa-square" />}
    //     </span></>,
    //   sortable: true,
    //   width: "2%",
    // },
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.createdAt).toDateString(),
      width: "10%",
    },
    {
      name: "Order ID",
      selector: (row) => row?.orderId,
      width: "10%",
      // width: "24ch",
    },
    {
      name: "Name",
      selector: (row) =>
        row?.userObj?.firstName
          ? row?.userObj?.firstName
          : "" + " " + row?.userObj?.lastName
            ? row?.userObj?.lastName
            : "",
      width: "10%",
    },

    {
      name: "Amount",
      selector: (row) => row?.totalAmount,
      width: "10%",
    },
    {
      name: "Payment Status",
      button: true,
      width: "25%",
      cell: (row) => (
        <CustomButton
          redBtn={row?.payment == "PENDING"}
          path={"/Order/Total-Order"}
          greenBtn={row?.payment != "PENDING"}
          btnName={row?.payment}
        />
      ),
    },
    {
      name: "Status",
      button: true,
      width: "25%",
      cell: (row) => (
        <CustomButton
          redBtn={row?.status == "CANCELLED"}
          path={"/Order/Total-Order"}
          greenBtn={row?.status != "CANCELLED"}
          btnName={row?.status}
        />
      ),
    },
    // {
    //   name: "Is Paid",
    //   button: true,
    //   width: "10%",
    //   cell: (row) => <CustomButton redBtn={row?.paymentObj?.paymentChk != 1} greenBtn={row?.paymentObj?.paymentChk == 1} btnName={row?.paymentObj?.paymentChk == 1 ? "PAID" : "PENDING"} />,
    // },
    {
      name: "Action",
      cell: (row) => (
        <CustomButton
          btnName={"View"}
          path={`/Order/Order-Detail?orderId=${row?._id}`}
          isLink
        />
      ),
      // cell: (row) => <CustomButton btnName={"View"} path={(row.status == orderStatusObj?.WAITINGFORAPPROVAL || row.status == orderStatusObj?.CREATED) ? `/Order/Order-Detail?orderId=${row?._id}` : `/Order/Sale-Detail?orderId=${row?._id}`} isLink />,
      // cell: (row) => <CustomButton btnName={"View"} path={`/Order/Order-Detail?orderId=${row?._id}`} isLink />,
    },
  ];

  const handleFilterByQuery = (e, requiredParametersArr) => {
    let tempArr = orders.filter((el) => {
      if (
        `${el.amountPaid}`.toLowerCase().includes(`${e}`.toLowerCase()) ||
        `${el.amountPending}`.toLowerCase().includes(`${e}`.toLowerCase()) ||
        `${el.totalAmount}`.toLowerCase().includes(`${e}`.toLowerCase())
        || `${el.userObj.name}`.toLowerCase().includes(`${e}`.toLowerCase())
        || `${el.userObj.firstName}`.toLowerCase().includes(`${e}`.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    // console.log(tempArr, "tempArrr234234");
    setQuery(e);
    setOrders([...tempArr]);
    // console.log([...tempArr], "...tempArr")
  };

  return (
    <DashboardTable className="mt-4">
      <div className="d-flex gap-3 justify-content-between mb-4">
        <h5 className="blue-1 m-0">{name}</h5>
        {displayButtons && (
          <div className="d-flex align-items-center justify-content-end mb-4">
            <div style={{ marginLeft: 15 }}>
              <select
                className="form-control"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Please Select Status</option>
                {Object.values(ORDER_STATUS).length > 0 &&
                  Object.values(ORDER_STATUS).map((el) => (
                    <option>{el}</option>
                  ))}
              </select>
            </div>
            <div style={{ marginLeft: 15 }}>
              <CustomButton
                isLink
                iconName="fa-solid fa-minus"
                ClickEvent={() => handleDeleteSelected()}
                btnName="Status Changed"
                path="/Order/Total-Order"
              />
            </div>
            {/* <div style={{ marginLeft: 15 }}>
                    <CustomButton isLink iconName="fa-solid fa-plus" ClickEvent={() => handleSetSelectedAsActive()} noIcon btnName="Set selected as Active" path="/Product/Product-List" />
                  </div>
                  <div style={{ marginLeft: 15 }}>
                    <CustomButton isLink iconName="fa-solid fa-plus" ClickEvent={() => handleSetSelectedAsInActive()} noIcon btnName="Set selected as In-Active" path="/Product/Product-List" />
                  </div> */}
            <div style={{ marginLeft: 15 }}>
              <CustomButton
                isLink
                iconName="fa-solid fa-plus"
                ClickEvent={() => handleClearSelection()}
                noIcon
                btnName="Clear Selection"
                path="/Order/Total-Order"
              />
            </div>
          </div>
        )}
        <SearchBox
          setQuery={(e) => {
            handleFilterByQuery(e, ["name"]);
          }}
          query={query}
          extraClass="bg-white"
        />
      </div>
      <DataTable columns={PendingOrder_columns} data={orders} pagination />
    </DashboardTable>
  );
}

export default PendingOrder;
