import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import PendingOrder from "../Order/TotalOrder/PendingOrder";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { getAllOrders } from "../../services/order.service";
import { ORDER_STATUS } from "../Utility/constants";
import moment from "moment/moment";
import { getUser } from "../../services/users.service";


function CustomerReports() {

    const [search, setSearch] = useState("")
    const [status, setStatus] = useState("All")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [usersArr, setUsersArr] = useState([]);

    const handleGetAllUsers = async () => {

        try {
            let query = ''
            if (status) {
                query += `status=${status}`;
            }
            if (search) {
                query += `&q=${search}`;
            }

            if (startDate) {
                query += `&startDate=${startDate}`;
            }
            const { data: res } = await getUser(query);
            setUsersArr(res?.data);
        } catch (error) {
            console.error(error);
        }

    };


    useEffect(() => {
        handleGetAllUsers();
    }, []);

    useEffect(() => {
        handleGetAllUsers();
    }, [search, status, startDate, endDate]);

    const [users_columns, setUsers_columns] = useState([
        {
            name: "ID",
            selector: (row, index) => index + 1,
            sortable: true,
        },

        {
            name: "Name",
            selector: (row) => `${row?.firstName} ${row?.lastName}`,
        },
        {
            name: "Email",
            selector: (row) => row.email,
        },
        {
            name: "Phone",
            selector: (row) => (row.phone || row.phone != "") ? row.phone : "NA",
        },
        // {
        //     name: "Total Spent",
        //     selector: (row) => row.totalSpend,
        //},
        {
            name: "Status",
            selector: (row) => row.isActive == true ? 'Active' : 'Inactive',
        },
        {
            name: "Created At",
            selector: (row) => new Date(row.createdAt).toDateString()
        },


    ]);

    return (
        <main>
            <section className="total-order" style={{ minHeight: "75vh" }}>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="dashboard-box">
                                <div className="d-flex gap-3 justify-content-between mb-4">
                                    <h5 className="blue-1 m-0">Customers</h5>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex gap-3 justify-content-between mb-4">
                                            {/* <div className="w-100">
                                                <label>
                                                    Status
                                                </label>
                                                <select className="form-control"   value={status} onChange={(e) => setStatus(e.target.value)} >
                                                    <option value="Order"> All Orders</option>
                                                    {
                                                        Object.values(ORDER_STATUS).length > 0 && Object.values(ORDER_STATUS).map(el => <option>{el}</option>)
                                                    }
                                                </select>
                                            </div> */}
                                            <div className="w-100 d-flex gap-3 justify-content-between">
                                                <div className="w-100">
                                                    <label>
                                                        Register Date
                                                    </label>
                                                    <input type="date" className="form-control" value={moment(startDate).format('YYYY-MM-DD')} onChange={(e) => setStartDate(e.target.value)} />
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" >
                            <DashboardTable className="mt-4">
                                <div className="d-flex gap-3 justify-content-between mb-4">
                                    <h5 className="blue-1 m-0">Customers</h5>
                                    {/* <div className="search-field">
                                        <form action="#" className="form">
                                            <div
                                                className="input-group"
                                            >
                                                <div className="input-group-text">
                                                    <i className="ion-ios-search-strong blue-1"></i>
                                                </div>
                                                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search" />
                                            </div>
                                        </form>
                                    </div> */}
                                </div>
                                <DataTable columns={users_columns} data={usersArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CustomerReports;
