import {
  addPaymentGateway,
  deletePaymentGateway,
  getPaymentGateway,
  updatePaymentGateway,
} from "../../../services/paymentGateway.service";

export const PAYMENTGATEWAY_ADD = "PAYMENTGATEWAY_ADD";
export const PAYMENTGATEWAY_ADD_SUCCESS = "PAYMENTGATEWAY_ADD_SUCCESS";
export const PAYMENTGATEWAY_ADD_FAIL = "PAYMENTGATEWAY_ADD_FAIL";

export const GET_ALL_PAYMENTGATEWAY = "GET_ALL_PAYMENTGATEWAYS";
export const GET_ALL_PAYMENTGATEWAY_SUCCESS = "GET_ALL_PAYMENTGATEWAYS_SUCCESS";
export const GET_ALL_PAYMENTGATEWAY_FAIL = "GET_ALL_PAYMENTGATEWAYS_FAIL";

export const UPDATE_PAYMENTGATEWAY_BY_ID = "UPDATE_PAYMENTGATEWAY_BY_ID";
export const UPDATE_PAYMENTGATEWAY_BY_ID_SUCCESS =
  "UPDATE_PAYMENTGATEWAY_BY_ID_SUCCESS";
export const UPDATE_PAYMENTGATEWAY_BY_ID_FAIL =
  "UPDATE_PAYMENTGATEWAY_BY_ID_FAIL";

export const SET_PAYMENTGATEWAY_OBJ = "SET_PAYMENTGATEWAY_OBJ";
export const SET_PAYMENTGATEWAY_OBJ_SUCCESS = "SET_PAYMENTGATEWAY_OBJ_SUCCESS";
export const SET_PAYMENTGATEWAY_OBJ_FAIL = "SET_PAYMENTGATEWAY_OBJ_FAIL";

export const GET_PAYMENTGATEWAY_BY_ID = "GET_PAYMENTGATEWAY_BY_ID";
export const GET_PAYMENTGATEWAY_BY_ID_SUCCESS =
  "GET_PAYMENTGATEWAY_BY_ID_SUCCESS";
export const GET_PAYMENTGATEWAY_BY_ID_FAIL = "GET_PAYMENTGATEWAY_BY_ID_FAIL";

export const DELETE_PAYMENTGATEWAY_BY_ID = "DELETE_PAYMENTGATEWAY_BY_ID";
export const DELETE_PAYMENTGATEWAY_BY_ID_SUCCESS =
  "DELETE_PAYMENTGATEWAY_BY_ID_SUCCESS";
export const DELETE_PAYMENTGATEWAY_BY_ID_FAIL =
  "DELETE_PAYMENTGATEWAY_BY_ID_FAIL";

export const PaymentGatewayAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENTGATEWAY_ADD });
    console.log(formData, "form raction ");
    let { data: response } = await addPaymentGateway(formData);
    if (response) {
      console.log(response, "response raction ");
      dispatch({
        type: PAYMENTGATEWAY_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(PaymentGatewayGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: PAYMENTGATEWAY_ADD_FAIL, payload: err });
  }
};

export const PaymentGatewayGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PAYMENTGATEWAY });
    let { data: response } = await getPaymentGateway(formData);
    if (response) {
      console.log(response, "action get  ");
      dispatch({
        type: GET_ALL_PAYMENTGATEWAY_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_PAYMENTGATEWAY_FAIL, payload: err });
  }
};

export const SetPaymentGatewayObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_PAYMENTGATEWAY_OBJ });
    if (formData) {
      console.log(formData, "formdAtaSetobj action");
      dispatch({
        type: SET_PAYMENTGATEWAY_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_PAYMENTGATEWAY_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_PAYMENTGATEWAY_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const PaymentGatewayUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PAYMENTGATEWAY_BY_ID });
    console.log(formData, "formData", id, "id");
    let { data: response } = await updatePaymentGateway(formData, id);
    if (response) {
      console.log(response, "res");
      dispatch({
        type: UPDATE_PAYMENTGATEWAY_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(PaymentGatewayGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_PAYMENTGATEWAY_BY_ID_FAIL, payload: err });
  }
};

export const PaymentGatewayDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PAYMENTGATEWAY_BY_ID });
    let { data: response } = await deletePaymentGateway(id);
    if (response) {
      console.log(response, "response daelete action ");
      dispatch({
        type: DELETE_PAYMENTGATEWAY_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(PaymentGatewayGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_PAYMENTGATEWAY_BY_ID_FAIL, payload: err });
  }
};
