import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BLOGCATEGORYGet } from "../../redux/actions/BlogCategory/BlogCategory.actions";
import { updateAdminPassword } from "../../services/users.service";
import QuillEditor from "../../utils/QuillEditor";
import { toastError } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import { toastSuccess } from "../Utility/ToastUtils";


import { generateFilePath } from "../Utility/utils";

export default function PasswordChange() {
    let userId = useSelector(state => state?.auth?.user?._id)


    const [previousPassword, setPreviousPassword] = useState("");
    const [password, setPassword] = useState("");


    // const handleTagGet = () => {
    //   dispatch(tagGet());
    // };
    // useEffect(() => {
    //   handleTagGet();
    // }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            if (`${previousPassword}` == '') {
                toastError('Previous Password is mandatory');
                return
            }

            if (`${password}` == '') {
                toastError('Password is mandatory');
                return
            }



            let obj = {
                password,
                previousPassword
            };

            let { data: res } = await updateAdminPassword(userId, obj);
            if (res.message) {
                toastSuccess(res.message)
            }
        }
        catch (err) {
            toastError(err)
        }
    };

    return (
        <main>
            <section className="product-category" style={{ minHeight: "75vh" }}>
                <div className="container-fluid p-0">
                    <form action="#" className="form">
                        <h5 className="blue-1 mb-4">
                            Update admin password
                        </h5>
                        <div className="row gy-4 gy-xxl-0">
                            <div className="col-12 col-xxl-12">
                                <DashboardBox>
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <label>
                                                Previous password <span className="red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={previousPassword}
                                                onChange={(e) => setPreviousPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>
                                                Password <span className="red">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <CustomButton
                                                isBtn
                                                btntype="button"
                                                iconName="fa-solid fa-check"
                                                btnName="Update"
                                                ClickEvent={handleSubmit}
                                            />
                                        </div>
                                    </div>
                                </DashboardBox>
                            </div>

                        </div>
                    </form>
                </div>
            </section>
        </main>
    );
}

