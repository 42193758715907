import {
  addContactInfo,
  deleteContactInfo,
  getContactInfo,
  updateContactInfo,
} from "../../../services/contactInfo.service";

export const CONTACTINFO_ADD = "CONTACTINFO_ADD";
export const CONTACTINFO_ADD_SUCCESS = "CONTACTINFO_ADD_SUCCESS";
export const CONTACTINFO_ADD_FAIL = "CONTACTINFO_ADD_FAIL";

export const GET_ALL_CONTACTINFO = "GET_ALL_CONTACTINFOS";
export const GET_ALL_CONTACTINFO_SUCCESS = "GET_ALL_CONTACTINFOS_SUCCESS";
export const GET_ALL_CONTACTINFO_FAIL = "GET_ALL_CONTACTINFOS_FAIL";

export const UPDATE_CONTACTINFO_BY_ID = "UPDATE_CONTACTINFO_BY_ID";
export const UPDATE_CONTACTINFO_BY_ID_SUCCESS =
  "UPDATE_CONTACTINFO_BY_ID_SUCCESS";
export const UPDATE_CONTACTINFO_BY_ID_FAIL = "UPDATE_CONTACTINFO_BY_ID_FAIL";

export const SET_CONTACTINFO_OBJ = "SET_CONTACTINFO_OBJ";
export const SET_CONTACTINFO_OBJ_SUCCESS = "SET_CONTACTINFO_OBJ_SUCCESS";
export const SET_CONTACTINFO_OBJ_FAIL = "SET_CONTACTINFO_OBJ_FAIL";

export const GET_CONTACTINFO_BY_ID = "GET_CONTACTINFO_BY_ID";
export const GET_CONTACTINFO_BY_ID_SUCCESS = "GET_CONTACTINFO_BY_ID_SUCCESS";
export const GET_CONTACTINFO_BY_ID_FAIL = "GET_CONTACTINFO_BY_ID_FAIL";

export const DELETE_CONTACTINFO_BY_ID = "DELETE_CONTACTINFO_BY_ID";
export const DELETE_CONTACTINFO_BY_ID_SUCCESS =
  "DELETE_CONTACTINFO_BY_ID_SUCCESS";
export const DELETE_CONTACTINFO_BY_ID_FAIL = "DELETE_CONTACTINFO_BY_ID_FAIL";

export const ContactInfoAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CONTACTINFO_ADD });
    console.log(formData, "form raction ");
    let { data: response } = await addContactInfo(formData);
    if (response) {
      console.log(response, "response raction ");
      dispatch({
        type: CONTACTINFO_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(CONTACTINFOGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: CONTACTINFO_ADD_FAIL, payload: err });
  }
};

export const CONTACTINFOGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CONTACTINFO });
    let { data: response } = await getContactInfo(formData);
    if (response) {
      console.log(response, "action ");
      dispatch({
        type: GET_ALL_CONTACTINFO_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_CONTACTINFO_FAIL, payload: err });
  }
};

export const SetContactInfoObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_CONTACTINFO_OBJ });
    if (formData) {
      console.log(formData, "formdAtaSetobj action");
      dispatch({
        type: SET_CONTACTINFO_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_CONTACTINFO_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_CONTACTINFO_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const ContactInfoUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CONTACTINFO_BY_ID });
    console.log(formData, "formData", id, "id");
    let { data: response } = await updateContactInfo(formData, id);
    if (response) {
      console.log(response, "");
      dispatch({
        type: UPDATE_CONTACTINFO_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(CONTACTINFOGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_CONTACTINFO_BY_ID_FAIL, payload: err });
  }
};

export const ContactInfoDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CONTACTINFO_BY_ID });
    let { data: response } = await deleteContactInfo(id);
    if (response) {
      console.log(response, "response daelete action ");
      dispatch({
        type: DELETE_CONTACTINFO_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(CONTACTINFOGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_CONTACTINFO_BY_ID_FAIL, payload: err });
  }
};
