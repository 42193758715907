import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/material";

export const addMaterial = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getMaterials = (query) => {
    return axiosApiInstance.get(`${serverUrl}/?${query}`);
};

export const deleteMaterial = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
export const updateMaterial = (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
